import React, {useState} from "react";
import {CreateResponse} from "../../interface/smart_article";
import {useMutation} from "react-query";
import {createSmartArticleRequest} from "../../http-request";
import {Field, Formik} from "formik";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear, faInfo} from "@fortawesome/free-solid-svg-icons";
import {MenuItem, Select} from "@mui/material";
import Loader from "../../components/Loader/loader";

const SingleForm = () => {
    const [fetching, setFetching] = useState(false)
    const [createResponse, setCreateResponse] = useState<CreateResponse>()
    const createRequestMutation = useMutation(createSmartArticleRequest);

    return (
        <div className={'smart-article'}>
            <div className={'smart-article-form'}>
                <Formik
                    initialValues={{
                        url: '',
                        description: '',
                        keywords: '',
                        internal_links: '',
                        number_of_generations: 3,
                        visualize_data: 'yes'
                    }}
                    validate={values => {
                        const errors : {[index: string]:any} = {}
                        if (!values.url) {
                            errors.url = 'Please enter the url';
                        }
                        if (!values.description) {
                            errors.description = 'Please enter the description';
                        }
                        if (!values.internal_links) {
                            errors.internal_links = 'Please enter the internal links';
                        }
                        if (!values.keywords) {
                            errors.keywords = 'Please enter the keywords';
                        }
                        if (!values.number_of_generations) {
                            errors.number_of_generations = 'Please enter the number of generations';
                        }
                        if (!values.visualize_data) {
                            errors.visualize_data = 'Please select the visualize data';
                        }
                        return errors;
                    }}
                    onSubmit={(values) => {
                        const submit = async () => {
                            setFetching(true)
                            createRequestMutation.mutateAsync(
                                {
                                    url: values.url,
                                    description: values.description,
                                    keywords: values.keywords,
                                    internal_links: values.internal_links,
                                    number_of_generations: values.number_of_generations,
                                    visualize_data: values.visualize_data,
                                },
                                {
                                    onSuccess: (res) => {
                                        setFetching(false)
                                        setCreateResponse(res)
                                    },
                                    onError: (res) => {
                                        setFetching(false)
                                    }
                                })
                        }
                        submit()
                    }}>
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit} className="border p-4">
                            <div className="row mt-5">
                                <div className="col-md-4">
                                    <div className={'row'}>
                                        <div className={'col-md-6'}>
                                            <label htmlFor="keywords">URL</label>
                                        </div>
                                        <div className={'col-md-6'}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="prompt-tooltip">
                                                        Website URL
                                                    </Tooltip>
                                                }
                                            >
                                                <div className={'fa-pull-right m-1'}>
                                                    <FontAwesomeIcon icon={faInfo}/>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="url"
                                        name="url"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.url}
                                    />
                                    <span
                                        className='input-error'>{errors.url && touched.url && errors.url}</span>
                                </div>
                                <div className="col-md-4">
                                    <div className={'row'}>
                                        <div className={'col-md-6'}>
                                            <label htmlFor="internal_links">Internal Links</label>
                                        </div>
                                        <div className={'col-md-6'}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="prompt-tooltip">
                                                        Internal links separated by commas.
                                                    </Tooltip>
                                                }
                                            >
                                                <div className={'fa-pull-right m-1'}>
                                                    <FontAwesomeIcon icon={faInfo}/>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="internal_links"
                                        name="internal_links"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.internal_links}
                                    />
                                    <span
                                        className='input-error'>{errors.internal_links && touched.internal_links && errors.internal_links}</span>
                                </div>
                                <div className="col-md-4">
                                    <div className={'row'}>
                                        <div className={'col-md-6'}>
                                            <label htmlFor="keywords">Keywords</label>
                                        </div>
                                        <div className={'col-md-6'}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="keywords-tooltip">
                                                        Keywords to be used on article separated by comma
                                                    </Tooltip>
                                                }
                                            >
                                                <div className={'fa-pull-right m-1'}>
                                                    <FontAwesomeIcon icon={faInfo}/>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="keywords"
                                        name="keywords"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.keywords}
                                    />
                                    <span
                                        className='input-error'>{errors.keywords && touched.keywords && errors.keywords}</span>
                                </div>
                            </div>
                            <div className={'row mt-4'}>
                                <div className="col-md-4">
                                    <div className={'row'}>
                                        <div className={'col-md-6'}>
                                            <label htmlFor="visualize_data">Visualize Data</label>
                                        </div>
                                        <div className={'col-md-6'}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="visualize_data-tooltip">
                                                        Visualize data from this article?
                                                    </Tooltip>
                                                }
                                            >
                                                <div className={'fa-pull-right m-1'}>
                                                    <FontAwesomeIcon icon={faInfo}/>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <Field value={values.visualize_data} name='visualize_data' as={Select}
                                           className='form-control select-form-group'>
                                        <MenuItem value='yes'>YES</MenuItem>
                                        <MenuItem value='no'>NO</MenuItem>
                                    </Field>
                                    <span
                                        className='input-error'>{errors.number_of_generations && touched.number_of_generations && errors.number_of_generations}</span>
                                </div>
                                <div className="col-md-4">
                                    <div className={'row'}>
                                        <div className={'col-md-6'}>
                                            <label htmlFor="number_of_generations">Number of Generations</label>
                                        </div>
                                        <div className={'col-md-6'}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="number_of_generations-tooltip">
                                                        Number of generation for article
                                                    </Tooltip>
                                                }
                                            >
                                                <div className={'fa-pull-right m-1'}>
                                                    <FontAwesomeIcon icon={faInfo}/>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <input
                                        type="number"
                                        step="1"
                                        className="form-control"
                                        id="number_of_generations"
                                        name="number_of_generations"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.number_of_generations}
                                    />
                                    <span
                                        className='input-error'>{errors.number_of_generations && touched.number_of_generations && errors.number_of_generations}</span>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className={'row'}>
                                        <div className={'col-md-6'}>
                                            <label htmlFor="description">Description</label>
                                        </div>
                                        <div className={'col-md-6'}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="description-tooltip">
                                                        Detailed information about the website
                                                    </Tooltip>
                                                }
                                            >
                                                <div className={'fa-pull-right m-1'}>
                                                    <FontAwesomeIcon icon={faInfo}/>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <textarea
                                        rows={4}
                                        className="form-control"
                                        id="description"
                                        name="description"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.description}
                                    />
                                    <span
                                        className='input-error'>{errors.description && touched.description && errors.description}</span>
                                </div>
                            </div>

                            <div className="text-center mt-4">
                                <button
                                    disabled={createRequestMutation.isLoading}
                                    className="btn m-1 btn-info"
                                    type="submit"
                                >
                                    <FontAwesomeIcon icon={faGear} /> Process
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            {fetching ? (
                <div className="fetch-details">
                    <Loader/>
                </div>
            ) : (
                <div>
                    <div className="result">
                        {createResponse !== undefined &&
                            <div style={{width: '100%'}}>
                                <div className="container mt-4 border p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className={"text-center"}>
                                                <div className="card mb-3">
                                                    <div className="card-header">Status Request ID</div>
                                                    <div className="card-body">
                                                        <p className="card-text">
                                                            To check the status of your request, please use the following request ID:
                                                            <br/><span
                                                            className="fw-bold">{createResponse.request_id}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
            }
        </div>
    )
}

export default SingleForm