import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons/faClose";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const PopUp = (props: {content: any, setData: any}) => {
    const handleClose = () => props.setData(undefined);

    return (
        <div>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className={'close-action'}>
                        <FontAwesomeIcon icon={faClose} onClick={handleClose}/>
                    </div>
                    <Typography component={"div"} id="modal-modal-description">
                        {props.content}
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}

export default PopUp
