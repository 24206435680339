import React, {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {WebsiteLink, WebsiteLinkList} from "../../../interface/seo-change-tracking";
import {getData} from "../../../http-request";
import Loader from "../../../components/Loader/loader";
import Datatable from "../../../components/Datatable/datatable";
import {GridRenderCellParams} from "@mui/x-data-grid";
import {Button} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import {getDateTimeWithoutTimezone} from "../../../components/utils";

const Links = (props: {website_id: number}) => {
    const { data, isFetching, refetch} = useQuery<WebsiteLinkList, Error>('seo-change-tracking-website-links',
        async () => {
            return await getData('/seo-change-tracking/links/?website_id='+props.website_id);
        });
    const [rows, setRows] = useState<WebsiteLink[]>([])

    useEffect(() => {
        if(data?.links){
            setRows(data?.links)
        }
    }, [data])

    const columns = [
        {
            field: 'sn',
            headerName: 'S.N',
            flex: 0.1,
        },
        {
            field: 'link',
            headerName: 'Link',
            flex: 3,
            renderCell: (params: GridRenderCellParams) => (
                <a  rel="noreferrer" target='_blank' href={params.value}>{params.value}</a>
            ),
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1
        },
        {
            field: 'created_at',
            headerName: 'First Scraped Date',
            flex: 3,
            renderCell:(params => getDateTimeWithoutTimezone(params.value))
        },
        {
            field: 'updated_at',
            headerName: 'Last Change Date',
            flex: 3,
            renderCell:(params => getDateTimeWithoutTimezone(params.value))
        },
    ]

    return (
        <div>
            {isFetching &&
            <Loader/>
            }
            {!isFetching && rows &&
            <div style={{width: '100%'}}>
                <div style={{textAlign: 'right'}} className={'mb-2'}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                            refetch()
                        }}>
                        <FontAwesomeIcon icon={faRefresh}/>&nbsp;Refresh
                    </Button>
                </div>
                <Datatable rows={rows.map((item: any, index: number) => {
                    return {...item, ...{sn: index + 1}}
                })} columns={columns} rowHeight={60}/>
            </div>
            }
        </div>
    )
}

export default Links
