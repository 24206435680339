import React from "react";
import './loader.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Button} from "@mui/material";

const Loader = () => {
    return (
        <div className={'loader-div'}>
            <div id="dt-spinner">
                {/*<div className="loader d-flex flex-column align-items-center justify-content-center">*/}

                {/*</div>*/}
                <FontAwesomeIcon icon={faSpinner} className={'loader'}/>
            </div>
        </div>
    )
}

export default Loader
