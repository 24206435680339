import React, {useEffect, useRef, useState} from "react";
import Tree from 'react-d3-tree'
import {LinkPositions, SiteMapResponse} from "../../../interface/interlinking";
import TreeNode from "./tree_node";
import Switch from '@mui/material/Switch';
import './interlink_tree.scss'
import {InputLabel, MenuItem, Select} from "@mui/material";
import CreateReport from "../../Report/create_report";


const containerStyles = {
    width: '90vw',
    height: '100vh'
}

const renderForeignObjectNode = ({ nodeDatum, toggleNode, foreignObjectProps }) => {
    return (
        <g>
            <foreignObject {...foreignObjectProps} cursor="grab">
                <TreeNode nodeData={nodeDatum} toggleNode={toggleNode}/>
            </foreignObject>
        </g>
    )
}

const inOrOutLinkChildren = (inLink: boolean, source_url:string, inlinks: {}, outlinks: {}, selectedPositions: string[],
                             input_type: string) => {
    let targetLink;
    if(inLink){
        targetLink = inlinks[source_url] || {}
    }else{
        targetLink = outlinks[source_url] || {}
    }
    if(input_type === "file"){
        return Object.keys(targetLink).filter(value => selectedPositions.includes(targetLink[value]['position']))
            .map((val) => {
                return {
                    name: targetLink[val]['anchor_text'],
                    position: targetLink[val]['position'],
                    href: val
                }
            })
    }else{
        return Object.keys(targetLink).map((val) => {
            return {
                name: targetLink[val]['anchor_text'],
                position: targetLink[val]['position'],
                href: val
            }
        })
    }
}

const prepareData = (data: SiteMapResponse, inLink: boolean, pivotRule:string, linkPositions: LinkPositions[]) => {
    let selectedPositions = linkPositions.filter(value => value.checked).map(value => value.name)
    if(pivotRule === data.site_url){
        let row = {
            name: data.site_url,
            href: data.site_url
        }
        row['children'] = data.site_map.map((article) => {
            return {
                'name': article,
                'href': article,
                'children': inOrOutLinkChildren(inLink, article, data.inlink, data.outlink, selectedPositions, data.input_type)
            }
        })
        return row
    }else{
        return {
            name: pivotRule,
            href: pivotRule,
            children: inOrOutLinkChildren(inLink, pivotRule, data.inlink, data.outlink, selectedPositions, data.input_type)
        }
    }
}

const getInitialLinkPositions = (link_positions) => {
    return link_positions.map(value => ({name: value, checked: true}))
}

const  InterLinkTree = (props: {data: SiteMapResponse, isReport: boolean}) => {
    const nodeSize = { x: 250, y: 300 }
    const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: -125, y: -40 }
    const [state, setState] = useState({translate:{x: 0,y: 0}}); // Set an initial state
    const [inLink, setInLink] = useState(true)
    const treeContainer = useRef(null);
    const rules = [props.data.site_url].concat(props.data.site_map)
    rules.sort()
    const [linkPositions, setLinkPositions] = useState<LinkPositions[]>([]);
    const [pivotRule, setPivotRule] = useState("")
    let data = prepareData(props.data, inLink, pivotRule, linkPositions)
    useEffect(()=> {
        // @ts-ignore
        const dimensions = treeContainer?.current?.getBoundingClientRect() || undefined;
        setState({
            translate: {
                x: dimensions.width / 2.4,
                y: 100
            }
        });
        if(props.data.input_type === "file"){
            setLinkPositions(getInitialLinkPositions(props.data.link_positions))
        }
        setPivotRule(props.data.site_url)
    },[props.data]);
    return (
        <div>
            {!props.isReport &&
            <div>
                <CreateReport data={props.data} tool_name={'Interlink'}/>
            </div>
            }
            <div className='graph-view-options'>
                <div className='graph-view-options-item'>
                    <label>
                        Inlink
                    </label>
                    <Switch className='graph-view-switch' onChange={() => setInLink(!inLink)}
                            checked={inLink}
                    />
                </div>
                <div className='graph-view-options-item'>
                    <label>
                        Outlink
                    </label>
                    <Switch className='graph-view-switch' onChange={() => setInLink(!inLink)}
                            checked={!inLink}
                    />
                </div>
                {props.data.input_type === "file" ?
                    linkPositions.map((checkbox, index) =>
                        <div key={"graph-view-options-item-"+checkbox.name} className='graph-view-options-item'>
                            <label>
                                {checkbox.name}
                            </label>
                            <Switch className='graph-view-switch'
                                    onChange={e => {
                                        const newCheckboxes = [...linkPositions];
                                        newCheckboxes[index].checked = e.target.checked;
                                        setLinkPositions(newCheckboxes);
                                    }}
                                    checked={checkbox.checked}/>
                        </div>
                    ) : null}
            </div>
            <div className='orgchart campaignTree'>
                <div className='campaign-rule-selector'>
                    <InputLabel shrink id="rule_selector">Articles</InputLabel>
                    <Select
                        title='Select/Pivot Rule'
                        name='rule_selector'
                        className='form-control select-form-group'
                        value={pivotRule}
                        onChange={e => setPivotRule(e.target.value)}
                        placeholder={'Select Pivot Rule'}>
                        {rules.map((val) => {
                            return (
                                <MenuItem key={val} value={val}>{val}</MenuItem>
                            )
                        })}
                    </Select>
                </div>
                <div style={containerStyles} ref={treeContainer}>
                    <Tree
                        data={data}
                        nodeSize={nodeSize}
                        translate={state.translate}
                        separation={{ siblings: 2, nonSiblings: 2 }}
                        renderCustomNodeElement={rd3tProps =>
                            renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
                        }
                        orientation='vertical'
                        pathFunc={'step'}
                    />
                </div>
            </div>
        </div>
    )
}

export default InterLinkTree
