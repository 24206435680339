import axios from "axios";
import {logoutApplication, logout} from "./features/Authentication/userSlice";
import {store} from './store';
import {notifyError} from './notify'

export const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    crossorigin: true,
    headers: {
        "Content-type": "application/json"
    }
});

apiClient.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    const applicationToken = localStorage.getItem("applicationToken")
    config.headers['X-Application-Token'] =  applicationToken ? `Bearer ${applicationToken}` : '';
    return config;
});


const {dispatch} = store;
apiClient.interceptors.response.use(
    response => response,
    error => {
        const {response} = error
        if(response && response.status === 401){
            if(response.data.error.startsWith("GSC-")){
                dispatch(logout())
                notifyError({title: response.data.error.replace("GSC-", "")})
            }else{
                dispatch(logoutApplication())
                notifyError({title: response.data.error})
                setTimeout(function (){
                    window.location.href = "/"
                }, 1000)
            }
            return Promise.reject(error);
        }else if(response && response.status === 403){
            notifyError({title: response?.data.error || error.message})
            setTimeout(function (){
                window.location.href = "/"
            }, 500)
        } else{
            notifyError({title: response?.data.error || error.message})
            return Promise.reject(error);
        }
    }
);
