import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { StylesProvider } from "@material-ui/core/styles"
import './tab.scss'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <Typography
            component='div'
            role='tabpanel'
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    )
}

/**
 * Prepares tab specific props
 * @param index, index of particular tab
 * @returns {{'aria-controls': string, id: string}}
 */
function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`
    }
}

/**
 * A functional component for main Tab View
 * @param props, object
 * @returns node
 */
export default function TabComponent(props) {
    let { labelContentMap, variant, scrollButtons, disableRipple } = props
    let tabLabels = Object.keys(labelContentMap)
    let tabContents = Object.values(labelContentMap)

    //first tab is selected by default
    const [value, setValue] = React.useState(0)

    //handles tab switch
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <div className='tab-component-root'>
            {/* injectFirst will inject material ui's default styling first so that we
       can override them later. */}
            <StylesProvider injectFirst>
                <AppBar position='static' color='default'>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant={variant}
                        scrollButtons={scrollButtons}
                        classes={{ indicator: 'tab-component-tabs-indicator' }}
                        aria-label='scrollable force tabs example'
                    >
                        {generateTabs(tabLabels, disableRipple)}
                    </Tabs>
                </AppBar>
                {generateTabPanels(tabContents, value)}
            </StylesProvider >
        </div>
    )
}

TabComponent.defaultProps = {
    variant: 'scrollable',
    scrollButtons: 'on',
    disableRipple: true
}


/**
 * Generates array of Tab
 * @param tabLabels, labels for all tabs
 * @param disableRipple, to enable/disable ripple effect
 * @returns array of Tab
 */
function generateTabs(tabLabels, disableRipple) {
    return tabLabels.map((tabLabel, index) => {
        let preparedTabLabel = prepareTabLabel(tabLabel, 20)
        return (
            <Tab
                key={index}
                disableRipple={disableRipple}
                label={preparedTabLabel}
                {...a11yProps(index)}
            />
        )
    })
}

/**
 * Prepares tab label by processing it (if any)
 * @param tabLabel, label of tab
 * @param maxLength, maximum length allowed for tab label
 * @returns final tab label
 */
function prepareTabLabel(tabLabel, maxLength) {
    let tabLabelLength = tabLabel.length

    //truncate if length of tab label is greater than maxLength
    return tabLabelLength > maxLength ? tabLabel.substring(0, maxLength) + '...' : tabLabel
}

/**
 * Generates array of TabPanel
 * @param tabContents, contents for all tabs
 * @param value, index value of currently selected tab
 * @returns array of TabPanel
 */
function generateTabPanels(tabContents, value) {
    return tabContents.map((tabContent, index) => (
        <TabPanel key={index} value={value} index={index}>
            {tabContent}
        </TabPanel>
    ))
}
