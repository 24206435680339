import React from "react";
import {WebsiteActivity} from "../../../interface/seo-change-tracking";
import {getTagAndLocation, getTableHeader} from "../../../components/utils";
import ReactDiffViewer from 'react-diff-viewer'
import _ from 'loadsh'

const MultiAttributeElementModified = (activity) => {
    const previousContent = JSON.parse(activity.previous_content) || {}
    const newContent = JSON.parse(activity.new_content) || {}
    const keys = _.union(Object.keys(previousContent), Object.keys(newContent))
    return (
        <div>
            {
                keys.map(key => {
                    return (
                        <div key={key+'-multi-attribute-element'} className={'multi-attribute-element'}>
                            <span>{key.toUpperCase()}</span><ReactDiffViewer oldValue={previousContent[key]} newValue={newContent[key]} splitView={true} />
                        </div>
                    )
                })
            }
        </div>
    )
}

const FormattedContent = (content) => {
    try{
        const json = JSON.parse(content)
        let fullInfo = "<ul class='json-display-field'>"
        Object.keys(json).forEach(value => {
            if(json[value]){
                if(value === 'href'){
                    fullInfo += `<li>${value} => <a rel="noreferrer" target='_blank' href=${json[value]}>${json[value]}</a></li>`
                }else{
                    fullInfo += `<li>${value} => ${json[value]}</li>`
                }
            }
        })
        fullInfo += "</ul>"
        return <div dangerouslySetInnerHTML={{__html: fullInfo}}/>
    }catch (e) {
        return <span>{content}</span>
    }
}

export const ElementModified = (elementActivity: WebsiteActivity) => {
    const activity = elementActivity.activity
    const [location, tag] = getTagAndLocation(activity.element)
    return (
        <div className={'element-modified'}>
            {getTableHeader(elementActivity.id, elementActivity.created_at)}
            <table className={'table table-bordered'}>
                <tbody>
                <tr>
                    <td width={'15%'}>Change</td>
                    <td width={'8%'}>Element</td>
                    <td width={'8%'}>Location</td>
                    <td>Diff</td>
                </tr>
                <tr className={'element-modified-td'}>
                    <td>ELEMENT MODIFIED</td>
                    <td>{tag}</td>
                    <td>{location}</td>
                    <td>
                        { tag.startsWith('a') ?
                            MultiAttributeElementModified(activity) :
                            <ReactDiffViewer oldValue={activity.previous_content} newValue={activity.new_content} splitView={true} />
                        }
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export const ElementAdded = (elementActivity: WebsiteActivity) => {
    const activity = elementActivity.activity
    const [location, tag] = getTagAndLocation(activity.element)
    return (
        <div className={'element-added'}>
            {getTableHeader(elementActivity.id, elementActivity.created_at)}
            <table className={'table table-bordered'}>
                <tbody>
                <tr>
                    <td width={'15%'}>Change</td>
                    <td width={'8%'}>Element</td>
                    <td width={'8%'}>Location</td>
                    <td>Content</td>
                </tr>
                <tr className={'element-added-td'}>
                    <td>ELEMENT ADDED</td>
                    <td>{tag}</td>
                    <td>{location}</td>
                    <td>{FormattedContent(activity.new_content)}</td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export const ElementRemoved = (elementActivity: WebsiteActivity) => {
    const activity = elementActivity.activity
    const [location, tag] = getTagAndLocation(activity.element)
    return (
        <div className={'element-removed'}>
            {getTableHeader(elementActivity.id, elementActivity.created_at)}
            <table className={'table table-bordered'}>
                <tbody>
                <tr>
                    <td width={'15%'}>Change</td>
                    <td width={'8%'}>Element</td>
                    <td width={'8%'}>Location</td>
                    <td>Content</td>
                </tr>
                <tr className={'element-removed-td'}>
                    <td>ELEMENT REMOVED</td>
                    <td>{tag}</td>
                    <td>{location}</td>
                    <td>{FormattedContent(activity.content)}</td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

