import React, {useEffect, useState} from "react";
import './sifter.scss'
import {Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/Loader/loader";
import CreateReport from "../Report/create_report";
import {useMutation} from "react-query";
import {getSifterResult} from "../../http-request";
import MindMapContainer from "../SearchConsole/IndividiualSite/mind_map_container";

const Sifter = () => {
    const [fetching, setFetching] = useState(false)
    const [sifterResponse, setSifterResponse] = useState()
    const [isReport, setIsReport] = useState(false)
    const getSifterResultMutation = useMutation(getSifterResult);

    useEffect(() => {
        if(sessionStorage.getItem('report')){
            setSifterResponse(JSON.parse(sessionStorage.getItem('report') || '{}'))
            sessionStorage.removeItem('report')
            setIsReport(true)
        }
    }, []);

    return (
        <div className={'page-rank'}>
            <div className={'page-rank-form'}>
                <Formik
                    initialValues={{ keyword_file: '', keywords: ''}}
                    validate={values => {
                        const errors : {[index: string]:any} = {}
                        if (!values.keywords && !values.keyword_file) {
                            errors.keywords = 'please enter keywords or upload file..';
                            errors.keyword_file = 'please enter keywords or upload file..';
                        }
                        return errors;
                    }}
                    onSubmit={(values) => {
                        const submit = async () => {
                            setFetching(true)
                            setSifterResponse(undefined)
                            getSifterResultMutation.mutateAsync(
                                {
                                    keyword_file: values.keyword_file,
                                    keywords: values.keywords
                                },
                                {
                                    onSuccess: (res) => {
                                        setFetching(false)
                                        setSifterResponse(res.data)
                                    },
                                    onError: (res) => {
                                        setFetching(false)
                                    }
                                })
                        }
                        submit()
                    }}>
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="row custom-row">
                                <div className='form-group col-md-4'>
                                    <textarea
                                        className="form-control col-md-6"
                                        name="keywords"
                                        id="keywords"
                                        placeholder="Keyword List"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.keywords}
                                        rows={10}
                                    />
                                    <span className='input-error'>{errors.keywords && touched.keywords && errors.keywords}</span>
                                </div>
                                <div className="form-group col-md-2 text-center">
                                    <h3>OR</h3>
                                </div>
                                <div className='form-group col-md-4'>
                                    <input
                                        type="file"
                                        className="form-control col-md-6"
                                        name="keyword_file"
                                        id="keyword_file"
                                        accept="text/csv"
                                        onChange={(event) => {
                                            const files = event.currentTarget.files;
                                            if(files !== null){
                                                setFieldValue("keyword_file", files[0]);
                                            }
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <span className='input-error'>{errors.keyword_file && touched.keyword_file && errors.keyword_file}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center mt-4">
                                    <button disabled={getSifterResultMutation.isLoading} className='btn btn-info search-sitemap-urls' type="submit">
                                        <FontAwesomeIcon icon={faGear}/> Process
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            { fetching ? (
                <div className="fetch-details">
                    <Loader/>
                </div>
            ) : (
                <div className="result">
                    { sifterResponse !== undefined &&
                    <div style={{width: '100%'}}>
                        {!isReport &&
                        <div>
                            <CreateReport data={sifterResponse} tool_name={'Sifter'}/>
                        </div>
                        }
                        <MindMapContainer site_url={"Clusterizer"} data={sifterResponse} responseType={'keyword_cluster'}/>
                    </div>
                    }
                </div>
            )
            }
        </div>
    )
}

export default Sifter;
