import React, {useEffect, useState} from "react";
import { RootState } from '../../../store'
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../../../features/Authentication/userSlice'
import {getAuthUrl} from '../http-request'
import {useSearchParams} from "react-router-dom";
import Loader from "../../../components/Loader/loader";
import './user.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserGear } from '@fortawesome/free-solid-svg-icons'


const User = () => {
    const [authUrl, setAuthUrl] = useState()
    const [error, setError] = useState(false)
    const userState = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams();
    let code = searchParams.get('code')
    if(code){
        window.history.pushState(
            "object or string",
            "Title",
            window.location.href.split("?")[0]);
        dispatch(login(code))
        searchParams.delete('code')
    }
    useEffect(() => {
        if(!userState.loggedIn){
            getAuthUrl().then((response) => {
                if(response.data){
                    setAuthUrl(response.data.auth_url)
                }else{
                    setError(true)
                }
            })
        }
    }, [userState.loggedIn]);

    return (
        <div className={"text-center"}>
            {authUrl && !userState.loggedIn ?
            <a className="blinking btn btn-outline-success my-2 my-sm-0" href={authUrl}>
                <FontAwesomeIcon icon={faUserGear}/> Login with Google</a>
             : [(!userState.loggedIn && !error && <Loader key={'loader'}/> )] }
        </div>
    )
}
export default User;
