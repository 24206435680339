// @ts-ignore
import React, {useEffect, useState} from 'react';
import './interlinking.scss'
import {useMutation} from "react-query";
import {startSiteScrapping, getScrappingDetail} from "../../http-request";
import {SiteMapResponse} from "../../interface/interlinking";
import {Formik} from "formik";
import Loader from "../../components/Loader/loader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import InterLinkTree from "./InterlinkTree/interlink_tree";
import {getDateTimeWithoutTimezone} from "../../components/utils";


const startScrape = (startScrappingMutation, site_url, getScrappingDetailMutation, setSiteMapResponse, setFetching, setFetchDetails) => {
    setFetchDetails("Scrapping site maps")
    startScrappingMutation.mutateAsync({site_url: site_url},
        {
            onSuccess: (res) => {
                setFetchDetails("Completed Scrapping site maps")
                const fetchDetail = setInterval(() => {
                    getScrappingDetailMutation.mutateAsync({site_url: site_url}, {
                        onSuccess: (response) => {
                            const getScrappingDetailResponse = response.data
                            if (getScrappingDetailResponse?.status === "COMPLETED") {
                                clearInterval(fetchDetail)
                                setFetching(false)
                                getScrappingDetailResponse['site_url'] = site_url
                                setSiteMapResponse(getScrappingDetailResponse)
                                setFetchDetails("")
                            }else if(getScrappingDetailResponse.status === "FETCHING") {
                                setFetchDetails(getScrappingDetailResponse.message)
                            }
                        }
                    });
                }, 5000)
            }
        });
}

const handleInput = (getScrappingDetailMutation, site_url, input_file, startScrappingMutation, setSiteMapResponse,
                        setFetching, setFetchDetails) => {
    getScrappingDetailMutation.mutateAsync({site_url: site_url, input_file: input_file}, {
        onSuccess: (response) => {
            const getScrappingDetailResponse = response.data
            if(getScrappingDetailResponse.input_type === "file"){
                setFetching(false)
                getScrappingDetailResponse['site_url'] = site_url
                setSiteMapResponse(getScrappingDetailResponse)
            }else{
                if(getScrappingDetailResponse?.status === "COMPLETED"){
                    const dt = getDateTimeWithoutTimezone(getScrappingDetailResponse.updated_at)
                    Swal.fire({
                        title: 'Previous record found for entered URL, do you want to re-fetch ?',
                        text: 'Last Updated: '+dt.toString(),
                        showDenyButton: true,
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        denyButtonText: 'No',
                        customClass: {
                            actions: 'my-actions',
                            cancelButton: 'order-1 right-gap',
                            confirmButton: 'order-2',
                            denyButton: 'order-3',
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {
                            startScrape(startScrappingMutation, site_url,
                                getScrappingDetailMutation, setSiteMapResponse, setFetching,
                                setFetchDetails)
                        }else{
                            setFetching(false)
                            getScrappingDetailResponse['site_url'] = site_url
                            setSiteMapResponse(getScrappingDetailResponse)
                        }
                    })
                }else{
                    startScrape(startScrappingMutation, site_url, getScrappingDetailMutation,
                        setSiteMapResponse, setFetching, setFetchDetails)
                }
            }
        }
    })
}

const Interlinking = () => {
    const startScrappingMutation = useMutation(startSiteScrapping);
    const getScrappingDetailMutation = useMutation(getScrappingDetail);
    const [siteMapResponse, setSiteMapResponse] = useState<SiteMapResponse>()
    const [fetching, setFetching] = useState(false)
    const [fetchDetail, setFetchDetails] = useState("")
    const [isReport, setIsReport] = useState(false)
    useEffect(() => {
        if(sessionStorage.getItem('report')){
            setSiteMapResponse(JSON.parse(sessionStorage.getItem('report') || '{}'))
            sessionStorage.removeItem('report')
            setIsReport(true)
        }
    }, []);
    return (
        <div>
            <div className='interlinking-form'>
                <Formik
                    initialValues={{ site_url: '', input_file: ''}}
                    validate={values => {
                        const errors : {[index: string]:any} = {}
                        if (!values.site_url && !values.input_file) {
                            errors.site_url = 'please enter the url for homepage or upload file..';
                        }
                        return errors;
                    }}
                    onSubmit={(values) => {
                        const submit = async () => {
                            setFetching(true)
                            if(values.site_url){
                                handleInput(getScrappingDetailMutation, values.site_url, values.input_file,
                                    startScrappingMutation, setSiteMapResponse, setFetching, setFetchDetails)
                            }else{
                                startScrappingMutation.mutateAsync({input_file: values.input_file, site_url: ""},
                                    {
                                        onSuccess: (res) => {
                                            setFetching(false)
                                            setSiteMapResponse(res.data)
                                        }
                                    })
                            }
                        }
                        submit()
                    }}>
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className='form-group col-md-5'>
                                    <input
                                        className="form-control col-md-6"
                                        id="site_url"
                                        name="site_url"
                                        placeholder="Site URL(Homepage Ex: https://parent.guide)"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.site_url}
                                    />
                                    <span className='input-error'>{errors.site_url && touched.site_url && errors.site_url}</span>
                                </div>
                                <div className="form-group col-md-2 text-center">
                                    <h3>OR</h3>
                                </div>
                                <div className='form-group col-md-5'>
                                    <input
                                        type="file"
                                        className="form-control col-md-6"
                                        name="input_file"
                                        id="input_file"
                                        accept="text/csv"
                                        onChange={(event) => {
                                            const files = event.currentTarget.files;
                                            if(files !== null){
                                                setFieldValue("input_file", files[0]);
                                            }
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <span className='input-error'>{errors.input_file && touched.input_file && errors.input_file}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center mt-4">
                                    <button disabled={startScrappingMutation.isLoading} className='btn btn-info search-sitemap-urls' type="submit">
                                        <FontAwesomeIcon icon={faGear}/> Process
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            { fetching ? (
                <div className="fetch-details">
                    <div className="text-center">{fetchDetail}</div>
                    <Loader/>
                </div>
            ) : (
                <div className="result">
                    {getScrappingDetailMutation.error instanceof Error ? (
                        <div>An error occurred: {getScrappingDetailMutation.error.message}</div>
                    ) : null}
                    { siteMapResponse !== undefined &&
                    <InterLinkTree data={siteMapResponse} isReport={isReport}/>
                    }
                </div>
            )
            }
        </div>
    )
}

export default Interlinking
