import React, {useState} from "react";
import './bulk-search.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {Button} from "@mui/material";
import {postRequest} from "../../../http-request";
import {GridRenderCellParams} from "@mui/x-data-grid";
import Datatable from "../../../components/Datatable/datatable";

const BulkSearch = (props: {site_url: string}) => {
    const [state, setState] = useState({links: "", time_period: 90, site_url: props.site_url})
    const [bulkSearchResponse, setBulkSearchResponse] = useState([])

    const changeState = (key, val) => {
        setState(prevState => ({...prevState, [key]: val}))
    }

    const bulkSearch = () => {
        postRequest('/search-console/bulk-search/', state).then(data => {
            setBulkSearchResponse(data.rows)
        })
    }

    let columns = [
        {
            field: 'id',
            headerName: 'S.N',
            flex: 0.1,
        },
        {
            field: 'page',
            headerName: 'Page',
            flex: 4,
            renderCell: (props: GridRenderCellParams) => (
                <a rel="noreferrer" target='_blank' href={props.value}>{props.value}</a>
            )
        },
        {
            field: 'keyword',
            headerName: 'Keyword',
            flex: 4
        },
        {
            field: 'clicks',
            headerName: 'Clicks',
            flex: 1
        },
        {
            field: 'ctr',
            headerName: 'CTR',
            flex: 1
        },
        {
            field: 'impressions',
            headerName: 'Impression',
            flex: 1
        },
        {
            field: 'positions',
            headerName: 'Positions',
            flex: 1
        }
    ]

    return (
        <div className={'bulk-search-console'}>
            <div className={'bulk-search-console-grid'}>
                <div className="form-group">
                    <label htmlFor="time-period">Last X Days</label>
                    <input id="time-period" name="time-period" className="form-control" type="number" value={state.time_period}
                           placeholder="Last X Days" onChange={e => changeState('time_period', e.target.value)}/>
                </div>
                <div className={'form-group'}>
                    <label htmlFor={'bulk-links'}>Links</label>
                    <textarea
                        value={state.links}
                        placeholder="Enter Links"
                        rows={5}
                        id={"links"}
                        name={'links'}
                        className={'form-control'}
                        onChange={(e) => changeState('links', e.target.value)}
                    />
                </div>
                <div className={'form-group text-left'}>
                    <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={() => {
                            bulkSearch()
                        }}>
                        <FontAwesomeIcon icon={faSearch}/>&nbsp;Search
                    </Button>
                </div>
            </div>
            { bulkSearchResponse !== undefined && bulkSearchResponse.length !== 0 &&
            <div className={'bulk-search-console-result'}>
                <Datatable
                    rows={bulkSearchResponse.map((item:any, index:number) => {return { ...{id: index+1}, ...item}})}
                    columns={columns}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'clicks', sort: 'desc' }],
                        },
                    }}
                />
            </div>
            }
        </div>
    )
}

export default BulkSearch
