import React, {useState} from "react"
import "./tree_node.scss"
import {faExpand, faMinimize} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const TreeNode = ({nodeData, toggleNode}) => {
    const [ruleButtonStyle, setRuleButtonStyle] = useState({display: 'none'})
    try{
        return (
            <div className="oc-node"
                 onMouseEnter={e => {setRuleButtonStyle({display: 'block'})}}
                 onMouseLeave={e => {setRuleButtonStyle({display: 'none'})}}
            >
                <div className="oc-node-div">
                    <div className={"rule-node"}>
                        {nodeData.href === nodeData.name ?
                        <a href={nodeData.href} target="_blank">{nodeData.name}</a> :
                            <div><p className='anchor-text'>{nodeData.name}</p><a href={nodeData.href} target="_blank">{nodeData.href}</a></div>
                        }
                    </div>
                </div>
                <div style={ruleButtonStyle} className={nodeData.children && 'rule-config-overlay'}>
                    {nodeData.children && nodeData.children.length > 0 && (
                        <div className='rule-node-button'>
                            {nodeData.__rd3t.collapsed ?
                                <FontAwesomeIcon icon={faExpand} onClick={toggleNode}/> : <FontAwesomeIcon icon={faMinimize} onClick={toggleNode}/>
                            }
                        </div>
                    )}
                </div>
            </div>
        )
    }catch (err){
        console.error(`Error occurred generating node for rule;error=${err};nodeData=${JSON.stringify(nodeData)}`)
        return (<></>)
    }
}

export default TreeNode
