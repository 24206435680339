import React from 'react';
import './header.scss';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import {Link} from "react-router-dom";
import {logoutApplication} from "../../features/Authentication/userSlice";
import {useDispatch, useSelector} from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPowerOff,
    faMagnifyingGlassChart,
    faEye,
    faNetworkWired,
    faRankingStar,
    faFile,
    faCodeCompare, faUsers,
} from '@fortawesome/free-solid-svg-icons'
import {RootState} from "../../store";

const Header = (props: {setLoggedIn: any}) => {
    const dispatch = useDispatch()
    const userState = useSelector((state: RootState) => state.user)
    const permissions = userState.permissions
    const logoutUser = () => {
        dispatch(logoutApplication())
        props.setLoggedIn(false)
    }
    return (
        <Navbar collapseOnSelect fixed='top' expand='sm' bg='dark' variant='dark'>
            <Container>
                <Navbar.Brand><Link className={"nav-link"} to='/'>Google API's Toolkit</Link></Navbar.Brand>
                <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                <Navbar.Collapse id='responsive-navbar-nav'>
                    <Nav>
                        {permissions.includes('search-console') && (
                            <Link className="nav-link" to='/search-console'>
                                <FontAwesomeIcon icon={faMagnifyingGlassChart}/> Search Console
                            </Link>
                        )}
                        {permissions.includes('vision') && (
                            <Link className="nav-link" to='/vision'>
                                <FontAwesomeIcon icon={faEye}/> Vision
                            </Link>
                        )}
                        {permissions.includes('interlinking') && (
                            <Link className="nav-link" to='/interlinking'>
                                <FontAwesomeIcon icon={faNetworkWired}/> Interlinking
                            </Link>
                        )}
                        {permissions.includes('pagerank') && (
                            <Link className="nav-link" to='/page-rank'>
                                <FontAwesomeIcon icon={faRankingStar}/> Pagerank
                            </Link>
                        )}
                        {permissions.includes('seo-change-tracking') && (
                            <Link className="nav-link" to='/seo-change-tracking'>
                                <FontAwesomeIcon icon={faCodeCompare}/> Seo Change Tracking
                            </Link>
                        )}
                        {permissions.includes('sifter') && (
                            <Link className="nav-link" to='/sifter'>
                                <FontAwesomeIcon icon={faCodeCompare}/> Sifter
                            </Link>
                        )}
                        { ['smart-article', 'chat-gpt', 'claude-ai'].filter(value => permissions.includes(value)).length > 0 &&
                            <NavDropdown title="AI Article" className="ai-article-dropdown">
                                {permissions.includes('smart-article') && (
                                    <NavDropdown.Item>
                                        <Link className={"nav-link"} to='/smart-article'>Smart Article</Link>
                                    </NavDropdown.Item>
                                )}
                                {permissions.includes('chat-gpt') && (
                                    <NavDropdown.Item>
                                        <Link className={"nav-link"} to='/chat-gpt'>Chat GPT</Link>
                                    </NavDropdown.Item>
                                )}
                                {permissions.includes('claude-ai') && (
                                    <NavDropdown.Item>
                                        <Link className={"nav-link"} to='/claude-ai'>Claude AI</Link>
                                    </NavDropdown.Item>
                                )}
                                {permissions.includes('chat-gpt') && (
                                    <NavDropdown.Item>
                                        <Link className={"nav-link"} to='/chat-gpt-rewrite'>Chat GPT Re Write</Link>
                                    </NavDropdown.Item>
                                )}
                                {permissions.includes('claude-ai') && (
                                    <NavDropdown.Item>
                                        <Link className={"nav-link"} to='/claude-ai-bulk'>Claude AI Bulk</Link>
                                    </NavDropdown.Item>
                                )}
                                {/*{permissions.includes('claude-ai') && (*/}
                                {/*    <NavDropdown.Item>*/}
                                {/*        <Link className={"nav-link"} to='/claude-ai-rewrite'>Claude AI Re Write</Link>*/}
                                {/*    </NavDropdown.Item>*/}
                                {/*)}*/}
                            </NavDropdown>
                        }
                        {permissions.includes('reports') && (
                            <Link className="nav-link" to='/reports'>
                                <FontAwesomeIcon icon={faFile}/> Reports
                            </Link>
                        )}
                        {permissions.includes('users') && (
                            <Link className="nav-link" to='/users'>
                                <FontAwesomeIcon icon={faUsers}/> Users
                            </Link>
                        )}
                        <a onClick={logoutUser} className="nav-link" style={{cursor: 'pointer'}}>
                            <FontAwesomeIcon icon={faPowerOff}/> Logout</a>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header;
