import {apiClient} from "../../http-common";

export const getAuthUrl = async () => {
    return await apiClient.get('/auth-url');
};

export const validateApplicationToken = async (data: {}) => {
    return await apiClient.post('/validate-application-token', data);
}

export const getSiteList = async () => {
    const response = await apiClient.get('/site-list/')
    return response.data
}

export const getSiteData = async (data: {site_url:string, filter:number, input_page:string, order_by:string, order:string}) => {
    const response = await apiClient.post('/search-data/', data)
    return response.data
}

export const getQueryData = async (data: {site_url:string, page:string, filter:number, input_query:string, order_by:string, order:string}) => {
    const response = await apiClient.post('/search-data-individual/', data)
    return response.data
}

export const exportFile =  (url:string, data: any) => {
    apiClient.post(url, data, {responseType: 'blob'})
        .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', data.filename || 'report.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
    });
}
