import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import './datatable.scss'
import {
    DataGrid, GridColumns, GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {withStyles} from "@material-ui/core";

function escapeRegExp(value:string) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const StyledDataGrid = withStyles({
    root: {
        "& .MuiDataGrid-renderingZone": {
            maxHeight: "none !important"
        },
        "& .MuiDataGrid-cell": {
            lineHeight: "unset !important",
            maxHeight: "none !important",
            whiteSpace: "normal"
        },
        "& .MuiDataGrid-row": {
            maxHeight: "none !important"
        }
    }
})(DataGrid);

const QuickSearchToolbar = (props: any) => {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
            }}
        >
            <div>
                <GridToolbarFilterButton />
                <GridToolbarExport />
                {/*<GridToolbarDensitySelector />*/}
            </div>
            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
                sx={{
                    width: {
                        xs: 1,
                        sm: 'auto',
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    '& .MuiSvgIcon-root': {
                        mr: 0.5,
                    },
                    '& .MuiInput-underline:before': {
                        borderBottom: 1,
                        borderColor: 'divider',
                    },
                }}
            />
        </Box>
    );
}

const Datatable = (data: {rows: object[], columns: GridColumns, rowHeight?:number, initialState?:object}) => {

    const [searchText, setSearchText] = React.useState('');
    const [rows, setRows] = React.useState<object[]>([]);

    const requestSearch = (searchValue:string) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows : object[] = data.rows.filter((row:{[index: string]:any}) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field].toString());
            });
        });
        setRows(filteredRows);
    };

    React.useEffect(() => {
        setRows(data.rows);
    }, [data.rows]);
    return (
        <Box sx={{ width: 1}}>
            <StyledDataGrid
                initialState={data.initialState}
                disableSelectionOnClick={true}
                rowCount={rows.length}
                autoHeight={true}
                components={{ Toolbar: QuickSearchToolbar }}
                rows={rows}
                rowHeight={data.rowHeight || 110}
                columns={data.columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                componentsProps={{
                    toolbar: {
                        value: searchText,
                        onChange(event:any) {
                            return requestSearch(event.target.value);
                        },
                        clearSearch: () => requestSearch(''),
                    },
                }}
            />
        </Box>
    );
}
export default Datatable
