import React from "react";
import './matching_images.scss';
import {GridRenderCellParams} from '@mui/x-data-grid';
import Datatable from "../../../components/Datatable/datatable";


const MatchingImages = (props: {matching_images: []}) => {
    const {matching_images} = props
    const columns = [
        {
            field: 'id',
            headerName: 'S.N',
            flex: 0.1,
        },
        {
            field: 'page',
            headerName: 'Page',
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <div><a  rel="noreferrer" target='_blank' href={params.value}>{params.value}</a></div>
            ),
        },
        {
            field: 'image',
            headerName: 'Image',
            width: 170,
            renderCell: (params: GridRenderCellParams) => (
                <div><a  rel="noreferrer" target='_blank' href={params.value}><img alt={""} height='150px' width='150px' src={params.value}/></a></div>
            ),
        },
        {
            field: 'match_type',
            headerName: 'Match Type',
            flex: 0.2
        }
    ];
    let rows = matching_images.map((item, index) => {
        return {id: index+1, page: item['page'], image: item['image'], match_type: item['match']}
    })
    return (
        <div style={{ width: '100%' }}>
            <Datatable rows={rows} columns={columns}/>
        </div>
    )
}

export default MatchingImages;
