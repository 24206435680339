import React from "react";
import './web_entity.scss';


const WebEntities = (props: {web_entities: []}) => {
    const {web_entities} = props
    return (
        <div className="text-center">
            <span>Entity/Score</span>
            <ul className="list-group" id="web-entities">
                {web_entities.map((entity, index) => {
                    return entity['description'] ? (
                        <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                            {entity['description']}<span className="badge badge-primary badge-pill">{entity['score']}</span></li>
                ) : ''
                })}
            </ul>
        </div>
    )
}

export default WebEntities;
