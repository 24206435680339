import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear, faInfo} from "@fortawesome/free-solid-svg-icons";
import {Formik} from "formik";
import {useMutation} from "react-query";
import Loader from "../../components/Loader/loader";
import {createClaudeAI} from "../../http-request";
import {ClaudeAICreateResponse} from "../../interface/claude-ai";
import './claude-ai.scss'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const ClaudeAI = () => {
    const [fetching, setFetching] = useState(false)
    const [claudeAICreateResponse, setClaudeAICreateResponse] = useState<ClaudeAICreateResponse>()
    const createClaudeAIMutation = useMutation(createClaudeAI);

    return (
        <div className={'claude-ai'}>
            <div className={'claude-ai-form'}>
                <Formik
                    initialValues={{
                        prompt: '',
                        system_instruction: localStorage.getItem('claude-ai-system-instruction') || 'You are an article writer.',
                        report_name: ''
                    }}
                    validate={values => {
                        const errors : {[index: string]:any} = {}
                        if (!values.prompt) {
                            errors.prompt = 'Please enter the prompt';
                        }
                        if (!values.system_instruction) {
                            errors.system_instruction = 'Please enter the system instruction';
                        }
                        if (!values.report_name) {
                            errors.report_name = 'Please enter the report name';
                        }
                        return errors;
                    }}
                    onSubmit={(values) => {
                        const submit = async () => {
                            setFetching(true)
                            localStorage.setItem('claude-ai-system-instruction', values.system_instruction)
                            createClaudeAIMutation.mutateAsync(
                                {
                                    prompt: values.prompt,
                                    system_instruction: values.system_instruction,
                                    report_name: values.report_name
                                },
                                {
                                    onSuccess: (res) => {
                                        setFetching(false)
                                        setClaudeAICreateResponse(res)
                                    },
                                    onError: (res) => {
                                        setFetching(false)
                                    }
                                })
                        }
                        submit()
                    }}>
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit} className="border p-4">
                            <div className={"row"}>
                                <h3 className={'text-center'}>Claude AI Article</h3>
                                <hr></hr>
                            </div>
                            <div className="row mt-4" style={{alignItems: 'center'}}>
                                <div className="col-md-12">
                                    <div className={'row'}>
                                        <div className={'col-md-6'}>
                                            <label htmlFor="report_name">Report Name</label>
                                        </div>
                                        <div className={'col-md-6'}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="report_name-tooltip">
                                                        Provide a unique name for the report.
                                                    </Tooltip>
                                                }
                                            >
                                                <div className={'fa-pull-right m-1'}>
                                                    <FontAwesomeIcon icon={faInfo}/>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="report_name"
                                        name="report_name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.report_name}
                                    />
                                    <span
                                        className='input-error'>{errors.report_name && touched.report_name && errors.report_name}</span>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className={'row'}>
                                        <div className={'col-md-6'}>
                                            <label htmlFor="system_instruction">System Instruction</label>
                                        </div>
                                        <div className={'col-md-6'}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="system_instruction-tooltip">
                                                        A system prompt is a way to provide context, instructions, and guidelines to Claude before presenting it with a question or task. By using a system prompt, you can set the stage for the conversation, specifying Claude's role, personality, tone, or any other relevant information that will help it better understand and respond to the user's input.
                                                    </Tooltip>
                                                }
                                            >
                                                <div className={'fa-pull-right m-1'}>
                                                    <FontAwesomeIcon icon={faInfo}/>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <textarea
                                        rows={4}
                                        className="form-control"
                                        id="system_instruction"
                                        name="system_instruction"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.system_instruction}
                                    />
                                    <span
                                        className='input-error'>{errors.system_instruction && touched.system_instruction && errors.system_instruction}</span>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className={'row'}>
                                        <div className={'col-md-6'}>
                                            <label htmlFor="prompt">Input Prompt</label>
                                        </div>
                                        <div className={'col-md-6'}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="prompt-tooltip">
                                                        Instructions provided to the AI for generating content.
                                                    </Tooltip>
                                                }
                                            >
                                                <div className={'fa-pull-right m-1'}>
                                                    <FontAwesomeIcon icon={faInfo}/>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <textarea
                                        rows={8}
                                        className="form-control"
                                        id="prompt"
                                        name="prompt"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.prompt}
                                    />
                                    <span
                                        className='input-error'>{errors.prompt && touched.prompt && errors.prompt}</span>
                                </div>
                            </div>
                            <div className="text-center mt-4">
                                <button
                                    disabled={createClaudeAIMutation.isLoading}
                                    className="btn m-1"
                                    style={{backgroundColor: 'coral'}}
                                    type="submit"
                                >
                                    <FontAwesomeIcon icon={faGear}/> Process
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            {fetching ? (
                <div className="fetch-details">
                    <Loader/>
                </div>
            ) : (
                <div>
                    <div className="result">
                        {claudeAICreateResponse !== undefined &&
                            <div style={{width: '100%'}}>
                                <div className="container mt-4 p-3">
                                    <div className={"text-center"}>
                                        <div className="card mb-3">
                                            <div className="card-body">
                                                {claudeAICreateResponse.success ? (
                                                    <div className="alert alert-success" role="alert">
                                                        Task has been queued successfully. Please check the Reports page
                                                        for status updates.
                                                    </div>
                                                ) : (
                                                    <div className="alert alert-danger" role="alert">
                                                        Error: {claudeAICreateResponse.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
            }
        </div>
    )
}

export default ClaudeAI
