import React from  "react";
import TabComponent from "../../components/Tab/tab";
import SingleForm from "./single-form";
import RequestStatus from "./request-status";
import FileInput from "./file-input";


const SmartArticle = () => {
    const tabs = {
        "Single Entry": <SingleForm/>,
        "File Input": <FileInput/>,
        "Request Status": <RequestStatus/>
    }
    return (
        <div className={"row"}>
            <h3 className={'text-center'}>Smart Article Writer</h3>
            <hr></hr>
            <TabComponent labelContentMap={tabs}/>
        </div>
    )
}

export default SmartArticle
