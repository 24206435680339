import React from 'react'
import {Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {useMutation} from "react-query";
import {loginRequest} from "../../http-request";
import './login.scss'
import {loginApplication} from "../../features/Authentication/userSlice";
import {useDispatch} from "react-redux";

const Login = () => {
    const loginMutation = useMutation(loginRequest);
    const dispatch = useDispatch()
    return (
        <div className='login-form'>
            <Formik
                initialValues={{ username: '', password: ''}}
                validate={values => {
                    const errors : {[index: string]:any} = {}
                    if (!values.username) {
                        errors.site_url = 'please enter the username.';
                    }
                    if (!values.password) {
                        errors.site_url = 'please enter the password.';
                    }
                    return errors;
                }}
                onSubmit={(values) => {
                    const submit = async () => {
                        loginMutation.mutateAsync({username: values.username, password: values.password}, {
                            onSuccess: (response) => {
                                dispatch(loginApplication({
                                    applicationToken: response.data.application_token,
                                    permissions: response.data.permissions
                                }))
                            }
                        })
                    }
                    submit()
                }}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit} className='row'>
                        <h4 className='text-center '>Login</h4>
                        <hr/>
                        <div className='form-group col-md-12 custom-form-group'>
                            <label id="username">Username</label>
                            <input
                                className="form-control"
                                id="username"
                                name="username"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.username}
                            />
                            <span className='input-error'>{errors.username && touched.username && errors.username}</span>
                        </div>
                        <div className='form-group col-md-12 custom-form-group'>
                            <label id="password">Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                            />
                            <span className='input-error'>{errors.password && touched.password && errors.password}</span>
                        </div>
                        <div className="form-group">
                            <button disabled={loginMutation.isLoading} className='btn btn-info search-sitemap-urls' type="submit">
                                <FontAwesomeIcon icon={faUser}/> Login
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default Login
