import React, {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {Report, ReportsList} from "../../interface/reports";
import {getData, deleteRequest} from "../../http-request";
import Loader from "../../components/Loader/loader";
import Datatable from "../../components/Datatable/datatable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faClose, faDownload, faRefresh} from "@fortawesome/free-solid-svg-icons";
import {Button} from "@mui/material";
import {notifyError, notifySuccess, userConfirmation} from "../../notify";
import './report.scss';
import { useNavigate } from "react-router-dom";
import {exportFile, getDateTimeWithoutTimezone} from "../../components/utils";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";


const toolNameToRoute = {
    'Search Console': 'search-console-report',
    'Interlink': 'interlinking',
    'Vision': 'vision',
    'NLP': 'nlp',
    'Pagerank': 'page-rank',
    'Sifter': 'sifter',
    'ChatGPT': 'chat-gpt',
    'ClaudeAI': 'claude-ai',
}

const dowlodableReportTool = [
    'ChatGPT',
    'ClaudeAI',
    'ChatGPTRewrite',
    'ClaudeAIRewrite',
    'ClaudeAIBulk',
]

const deleteReports = (reportName, rows, setRows, setLoading) => {
    userConfirmation('Are you sure you want to delete this Report', '').then((result) => {
        if(result.isConfirmed){
            setLoading(true)
            deleteRequest('/report/',{report_name: reportName}).then(data => {
                if(data.success){
                    notifySuccess({titleText: 'Report deleted successfully.'})
                    setRows(
                        rows.filter((r) => r.name !== reportName)
                    );
                }else{
                    notifyError({titleText: data.message})
                }
            }).finally(() => setLoading(false))
        }
    })
}

const retryJob = (reportName, setLoading, refetch) => {
    userConfirmation('Are you sure you want to retry this Task', '').then((result) => {
        if(result.isConfirmed){
            setLoading(true)
            getData(`/report/retry?name=${reportName}`).then(data => {
                if(data.success){
                    notifySuccess({titleText: 'Task retried successfully.'})
                    refetch()
                }else{
                    notifyError({titleText: data.message})
                }
            }).finally(() => setLoading(false))
        }
    })
}

const getReportData = (reportName, tool_name, setLoading, navigate) => {
    setLoading(true)
    getData('/report-data/?name='+reportName).then(data => {
        if(data.success){
            sessionStorage.setItem('report', JSON.stringify(data.data))
            navigate("/"+toolNameToRoute[tool_name], { replace: true });
        }
    }).finally(() => setLoading(false))
}

const downloadReportFile = (reportName, status, setLoading) => {
    if(status !== "COMPLETED"){
        notifyError({titleText: 'Report is not ready yet'})
        return
    }
    setLoading(true)
    getData('/report-data/?name='+reportName).then(data => {
        if(data.success){
            exportFile({content: data.data, fileName: `${reportName}.txt`})
        }
    }).finally(() => setLoading(false))
}

const columns = (rows, setRows, setLoading, navigate, refetch) => [
    {
        field: 'id',
        headerName: 'S.N',
        flex: 0.1,
    },
    {
        field: 'name',
        headerName: 'Name',
        flex: 3,
    },
    {
        field: 'tool_name',
        headerName: 'Tool',
        flex: 1,
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => (
            <OverlayTrigger
                key={params.value}
                placement="top"
                overlay={
                    <Tooltip id={`tooltip-${params.row.id}`}>
                        {params.row.description || params.value}
                    </Tooltip>
                }
            >
                <div
                    className={params.row.status === 'FAILED' ? 'text-danger' : ''}
                    style={{ cursor: params.row.description ? 'pointer' : 'auto' }}
                >
                    {params.value}
                </div>
            </OverlayTrigger>
        )
    },
    {
        field: 'created_at',
        headerName: 'Created At',
        flex: 3,
        renderCell: (params => getDateTimeWithoutTimezone(params.value))
    },
    {
        field: 'action',
        headerName: 'Action',
        flex: 3.5,
        renderCell: (params) => (
            <div className=''>
                {dowlodableReportTool.includes(params.row.tool_name) &&
                    <Button
                        variant="contained"
                        color="info"
                        size="small"
                        style={{ marginLeft: 10 }}
                        onClick={() => retryJob(params.row.name, setLoading, refetch)
                        }>
                        <FontAwesomeIcon icon={faRefresh}/>&nbsp;Retry
                    </Button>
                }
                {
                    dowlodableReportTool.includes(params.row.tool_name) ? (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 10 }}
                            disabled={params.row.status !== 'COMPLETED'}
                            onClick={() => downloadReportFile(params.row.name, params.row.status, setLoading)
                            }>
                            <FontAwesomeIcon icon={faDownload}/>&nbsp;Download
                        </Button>
                    ): (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 10 }}
                            onClick={() => getReportData(params.row.name, params.row.tool_name, setLoading, navigate)
                            }>
                            <FontAwesomeIcon icon={faEye}/>&nbsp;View
                        </Button>
                    )

                }
                <Button
                    variant="contained"
                    color="error"
                    size="small"
                    style={{ marginLeft: 10 }}
                    onClick={() => deleteReports(params.row.name, rows, setRows, setLoading)
                    }>
                    <FontAwesomeIcon icon={faClose}/>&nbsp;Delete
                </Button>
            </div>
        )
    }
]

const Reports = () => {
    let navigate = useNavigate();
    const { data, isFetching, refetch} = useQuery<ReportsList, Error>('user-reports',
        async () => {
            return await getData('/report/');
        });
    const [rows, setRows] = useState<Report[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(data?.reports){
            setRows(data?.reports)
        }
    }, [data])

    return (
        <div className={'text-center'}>
            {(isFetching || loading) &&
                <Loader/>
            }
            {!isFetching && rows &&
                <div style={{width: '100%'}}>
                    <div style={{textAlign: 'right'}} className={'mb-2'}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                                refetch()
                            }}>
                            <FontAwesomeIcon icon={faRefresh}/>&nbsp;Refresh
                        </Button>
                    </div>
                    <Datatable rows={rows.map((item: any, index: number) => {
                        return {...item, ...{id: index + 1}}
                    })} columns={columns(rows, setRows, setLoading, navigate, refetch)} rowHeight={60}/>
                </div>
            }
        </div>
    )
}

export default Reports
