import React from "react";
import './visually_similar_image.scss';


const VisuallySimilarImage = (props: {matching_images: []}) => {
    const {matching_images} = props
    return (
        <div className="row" id="visually_similar_images">
            {matching_images.map((image, index) => {
            return (<div key={index} className='col-md-3 vsi-img'>
                <a rel="noreferrer" target='_blank' href={image}>
                    <img alt={""} height='200px' width='100%' src={image}/>
                </a>
            </div>)
            })}
        </div>
    )
}

export default VisuallySimilarImage;
