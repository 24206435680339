import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear, faInfo} from "@fortawesome/free-solid-svg-icons";
import {Formik} from "formik";
import {useMutation} from "react-query";
import Loader from "../../components/Loader/loader";
import {createClaudeAIBulk} from "../../http-request";
import {ClaudeAICreateResponse} from "../../interface/claude-ai";
import './claude-ai.scss'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const ClaudeAIBulk = () => {
    const [fetching, setFetching] = useState(false)
    const [claudeAICreateResponse, setClaudeAICreateResponse] = useState<ClaudeAICreateResponse>()
    const createClaudeAIMutation = useMutation(createClaudeAIBulk);

    return (
        <div className={'claude-ai-bulk'}>
            <div className={'claude-ai-bulk-form'}>
                <Formik
                    initialValues={{
                        template: '',
                        system_instruction: localStorage.getItem('claude-ai-bulk-system-instruction') || 'You are an AI assistant.',
                        report_name: '',
                        variables: ''
                    }}
                    validate={values => {
                        const errors : {[index: string]:any} = {}
                        if (!values.template) {
                            errors.template = 'Please enter the template';
                        }
                        if (!values.system_instruction) {
                            errors.system_instruction = 'Please enter the system instruction';
                        }
                        if (!values.report_name) {
                            errors.report_name = 'Please enter the report name';
                        }
                        if (!values.variables) {
                            errors.variables = 'Please enter the variables';
                        }
                        return errors;
                    }}
                    onSubmit={(values) => {
                        const submit = async () => {
                            setFetching(true)
                            localStorage.setItem('claude-ai-bulk-system-instruction', values.system_instruction)
                            createClaudeAIMutation.mutateAsync(
                                {
                                    template: values.template,
                                    system_instruction: values.system_instruction,
                                    report_name: values.report_name,
                                    variables: values.variables
                                },
                                {
                                    onSuccess: (res) => {
                                        setFetching(false)
                                        setClaudeAICreateResponse(res)
                                    },
                                    onError: (res) => {
                                        setFetching(false)
                                    }
                                })
                        }
                        submit()
                    }}>
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit} className="border p-4">
                            <div className={"row"}>
                                <h3 className={'text-center'}>Claude AI Article Rewrite</h3>
                                <hr></hr>
                            </div>
                            <div className="row mt-4" style={{alignItems: 'center'}}>
                                <div className="col-md-4">
                                    <div className={'row'}>
                                        <div className={'col-md-6'}>
                                            <label htmlFor="report_name">Report Name</label>
                                        </div>
                                        <div className={'col-md-6'}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="report_name-tooltip">
                                                        Provide a unique name for the report.
                                                    </Tooltip>
                                                }
                                            >
                                                <div className={'fa-pull-right m-1'}>
                                                    <FontAwesomeIcon icon={faInfo}/>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="report_name"
                                        name="report_name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.report_name}
                                    />
                                    <span
                                        className='input-error'>{errors.report_name && touched.report_name && errors.report_name}</span>
                                </div>
                                <div className="col-md-8">
                                    <div className={'row'}>
                                        <div className={'col-md-6'}>
                                            <label htmlFor="variables">Variables</label>
                                        </div>
                                        <div className={'col-md-6'}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="variables-tooltip">
                                                        Comma separated values. For ex: Paris,NewYork,Berlin
                                                    </Tooltip>
                                                }
                                            >
                                                <div className={'fa-pull-right m-1'}>
                                                    <FontAwesomeIcon icon={faInfo}/>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <textarea
                                        rows={1}
                                        className="form-control"
                                        id="variables"
                                        name="variables"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.variables}
                                    />
                                    <span
                                        className='input-error'>{errors.variables && touched.variables && errors.variables}</span>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className={'row'}>
                                        <div className={'col-md-6'}>
                                            <label htmlFor="system_instruction">System Instruction</label>
                                        </div>
                                        <div className={'col-md-6'}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="system_instruction-tooltip">
                                                        A system prompt is a way to provide context, instructions, and
                                                        guidelines to Claude before presenting it with a question or
                                                        task. By using a system prompt, you can set the stage for the
                                                        conversation, specifying Claude's role, personality, tone, or
                                                        any other relevant information that will help it better
                                                        understand and respond to the user's input.
                                                    </Tooltip>
                                                }
                                            >
                                                <div className={'fa-pull-right m-1'}>
                                                    <FontAwesomeIcon icon={faInfo}/>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <textarea
                                        rows={4}
                                        className="form-control"
                                        id="system_instruction"
                                        name="system_instruction"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.system_instruction}
                                    />
                                    <span
                                        className='input-error'>{errors.system_instruction && touched.system_instruction && errors.system_instruction}</span>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className={'row'}>
                                        <div className={'col-md-6'}>
                                            <label htmlFor="template">Input Template</label>
                                        </div>
                                        <div className={'col-md-6'}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="template-tooltip">
                                                        Base template for the articles to be generated
                                                    </Tooltip>
                                                }
                                            >
                                                <div className={'fa-pull-right m-1'}>
                                                    <FontAwesomeIcon icon={faInfo}/>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <textarea
                                        rows={8}
                                        className="form-control"
                                        id="template"
                                        name="template"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.template}
                                    />
                                    <span
                                        className='input-error'>{errors.template && touched.template && errors.template}</span>
                                </div>
                            </div>
                            <div className="text-center mt-4">
                                <button
                                    disabled={createClaudeAIMutation.isLoading}
                                    className="btn m-1"
                                    style={{backgroundColor: 'coral'}}
                                    type="submit"
                                >
                                    <FontAwesomeIcon icon={faGear}/> Process
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            {fetching ? (
                <div className="fetch-details">
                    <Loader/>
                </div>
            ) : (
                <div>
                    <div className="result">
                        {claudeAICreateResponse !== undefined &&
                            <div style={{width: '100%'}}>
                                <div className="container mt-4 p-3">
                                    <div className={"text-center"}>
                                        <div className="card mb-3">
                                            <div className="card-body">
                                                {claudeAICreateResponse.success ? (
                                                    <div className="alert alert-success" role="alert">
                                                        Task has been queued successfully. Please check the Reports page
                                                        for status updates.
                                                    </div>
                                                ) : (
                                                    <div className="alert alert-danger" role="alert">
                                                        Error: {claudeAICreateResponse.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
            }
        </div>
    )
}

export default ClaudeAIBulk
