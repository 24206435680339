import React, {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {Website, WebsiteList} from "../../interface/seo-change-tracking";
import {getData, deleteRequest} from "../../http-request";
import Loader from "../../components/Loader/loader";
import Datatable from "../../components/Datatable/datatable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faLink, faTags, faArrowLeft, faDatabase, faRefresh} from "@fortawesome/free-solid-svg-icons";
import {Button} from "@mui/material";
import {notifyError, notifySuccess, userConfirmation} from "../../notify";
import './seo-change-tracking.scss';
import {AddWebsite, EditWebsite} from "./website_form";
import Links from "./Links/links";
import {GridRenderCellParams} from "@mui/x-data-grid";
import Elements from "./Elements/elements";
import Activities from "./Activities/activities";
import {getDateTimeWithoutTimezone} from "../../components/utils";


const deleteWebsite = (website, rows, setRows, setLoading) => {
    userConfirmation('Are you sure you want to delete this Website ? This will delete all data related to the website.', '').then((result) => {
        if(result.isConfirmed){
            setLoading(true)
            deleteRequest('/seo-change-tracking/',{website: website}).then(data => {
                if(data.success){
                    notifySuccess({titleText: 'Website deleted successfully.'})
                    setRows(
                        rows.filter((r) => r.website !== website)
                    );
                }else{
                    notifyError({titleText: data.message})
                }
            }).finally(() => setLoading(false))
        }
    })
}

const SeoChangeTracking = () => {
    const { data, isFetching, refetch} = useQuery<WebsiteList, Error>('seo-change-tracking-websites',
        async () => {
            return await getData('/seo-change-tracking/');
        });
    const [rows, setRows] = useState<Website[]>([])
    const [loading, setLoading] = useState(false)
    const [renderView, setRenderView] = useState(0)
    const [activeWebsite, setActiveWebsite] = useState<number>(0)

    useEffect(() => {
        if(data?.websites){
            setRows(data?.websites)
        }
    }, [data])

    const columns = [
        {
            field: 'sn',
            headerName: 'S.N',
            flex: 0.1,
        },
        {
            field: 'website',
            headerName: 'Website',
            flex: 2,
            renderCell: (params: GridRenderCellParams) => (
                <a  rel="noreferrer" target='_blank' href={params.value}>{params.value}</a>
            ),
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.8,
        },
        {
            field: 'configured_elements',
            headerName: 'Configured Elements',
            flex: 1.5,
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            flex: 1.5,
            renderCell:(params => getDateTimeWithoutTimezone(params.value))
        },
        {
            field: 'updated_at',
            headerName: 'Updated At',
            flex: 1.5,
            renderCell:(params => getDateTimeWithoutTimezone(params.value))
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 5,
            renderCell: (params) => (
                <div className='website-button-container'>
                    <Button
                        variant="text"
                        color="success"
                        size="small"
                        onClick={() => {
                            setRenderView(3)
                            setActiveWebsite(params.row.id)
                        }}>
                        <FontAwesomeIcon icon={faDatabase}/>&nbsp;Activities
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        size="small"
                        onClick={() => {
                            setRenderView(1)
                            setActiveWebsite(params.row.id)
                        }}>
                        <FontAwesomeIcon icon={faLink}/>&nbsp;Links
                    </Button>
                    <Button
                        variant="text"
                        color="secondary"
                        size="small"
                        onClick={() => {
                            setRenderView(2)
                            setActiveWebsite(params.row.id)
                        }}>
                        <FontAwesomeIcon icon={faTags}/>&nbsp;Elements
                    </Button>
                    <EditWebsite refetch={refetch} elements={params.row.configured_elements} website_id={params.row.id} website={params.row.website}/>
                    <Button
                        variant="text"
                        color="error"
                        size="small"
                        onClick={() => deleteWebsite(params.row.website, rows, setRows, setLoading)
                        }>
                        <FontAwesomeIcon icon={faClose}/>&nbsp;Delete
                    </Button>
                </div>
            )
        }
    ]

    return (
        <div className={'text-center'}>
            {renderView !== 0 &&
            <div className={'back-to-website-list'}>
                <Button
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={() => setRenderView(0)
                    }>
                    <FontAwesomeIcon icon={faArrowLeft}/>&nbsp;Back to Website List
                </Button>
            </div>
            }
            { renderView === 0 &&
            <div>
                {(isFetching || loading) &&
                <Loader/>
                }
                {!isFetching && rows &&
                <div style={{width: '100%'}}>
                    <AddWebsite refetch={refetch}/>
                    <div style={{textAlign: 'right'}} className={'mb-2'}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                                refetch()
                            }}>
                            <FontAwesomeIcon icon={faRefresh}/>&nbsp;Refresh
                        </Button>
                    </div>
                    <Datatable rows={rows.map((item: any, index: number) => {
                        return {...item, ...{sn: index + 1}}
                    })} columns={columns} rowHeight={80}/>
                </div>
                }
            </div>
            }
            { renderView === 1 && <Links website_id={activeWebsite}/> }
            { renderView === 2 && <Elements website_id={activeWebsite}/> }
            { renderView === 3 && <Activities website_id={activeWebsite}/> }
        </div>
    )
}

export default SeoChangeTracking
