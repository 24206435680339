import React, {useEffect, useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel, faMinusSquare, faPlusSquare} from "@fortawesome/free-regular-svg-icons";
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import {faExpand, faMaximize, faMinimize, faLink} from "@fortawesome/free-solid-svg-icons";
import {exportFile} from "../http-request";
import {SCIndividualSiteResponse} from "../../../interface/search_console";
import {mindMap} from "../../../components/MindMap/mind_map";
import {postRequest} from "../../../http-request";
import Loader from "../../../components/Loader/loader";

const MindMapContainer = (props: {site_url: string, data: SCIndividualSiteResponse, responseType?: string}) => {
    const [mindMapObject, setMindMapObject] = useState(Object)
    const fullScreenHandle = useFullScreenHandle();
    const [isFetching, setIsFetching] = useState(false)
    const [mindMeisterUrl, setMindMeisterUrl] = useState(null)

    const expandAll = () => {
        mindMapObject?.expand_all();
    }
    const collapseAll = () => {
        mindMapObject?.collapse_all();
    }
    const downloadClusterizedReport = () => {
        exportFile('/clusterized-report/', {site_url: props.site_url})
    }

    const getMindmeisterUrl = () => {
        if(mindMeisterUrl !== null){
            window.open(mindMeisterUrl, "_blank")
            return
        }
        setIsFetching(true)
        postRequest('/mind-meister-map-url/', {site_url: props.site_url}).then(data => {
            window.open(data.mind_meister_map_url, "_blank")
            setMindMeisterUrl(data.mind_meister_map_url)
        }).finally(()=>{
            setIsFetching(false)
        })
    }

    const zoomIn = (mindMapObject) => {
        mindMapObject?.view?.zoomIn()
    }

    const zoomOut = (mindMapObject) => {
        mindMapObject?.view?.zoomOut()
    }

    useEffect(() => {
        setMindMapObject(mindMap(props.data))
    }, [])

    return (
        <div className='mind-map-div'>
            {isFetching &&
                <Loader/>
            }
            <h3 className='mind-map-title'>Clusterize Keywords</h3>
            { props.responseType !== 'keyword_cluster' &&
            <div className={'download-report'}>
                <button className="btn btn-primary"
                        onClick={() => downloadClusterizedReport()}>
                    <FontAwesomeIcon icon={faFileExcel}/> Clusterized Report
                </button>
                <button className="btn btn-info"
                        onClick={() => getMindmeisterUrl()}>
                    <FontAwesomeIcon icon={faLink}/> Mindmeister
                </button>
            </div>
            }
            <div className="col-md-12 html5-fullscreen-api" id="fullscreen">
                <FullScreen handle={fullScreenHandle}>
                    <div id="jsmind_container">
                    </div>
                </FullScreen>
                <button onClick={fullScreenHandle.enter} className="fa fa-expand fs-button" aria-hidden="true">
                    <FontAwesomeIcon icon={faMaximize}/> FullScreen
                </button>

            </div>
            <div className="js-mind-control">
                <button id="zoom-in-button" onClick={() => zoomIn(mindMapObject)}
                        className="btn animated bounceInUp delay1 btn-secondary">
                    <FontAwesomeIcon icon={faPlusSquare} /> In
                </button>
                <button id="zoom-out-button" onClick={() => zoomOut(mindMapObject)}
                        className="btn animated bounceInUp delay1 btn-dark">
                    <FontAwesomeIcon icon={faMinusSquare} /> Out
                </button>
                <button className="sub btn animated bounceInUp delay2 btn-info" onClick={() => expandAll()}>
                    <FontAwesomeIcon icon={faExpand} /> Expand All
                </button>
                <button className="sub btn animated bounceInUp delay3 btn-success"
                        onClick={() => collapseAll()}>
                    <FontAwesomeIcon icon={faMinimize} /> Collapse All
                </button>
            </div>
        </div>
    )
}

export default MindMapContainer
