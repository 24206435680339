import React from "react";
import {GridRenderCellParams} from "@mui/x-data-grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import Switch from "@mui/material/Switch";
import Datatable from "../../../components/Datatable/datatable";
import TabComponent from "../../../components/Tab/tab";
import MindMapContainer from "./mind_map_container";
import GSCHeatMap from "../../../components/HeatMap/heatmap";

const calculateChange = (current_value, old_value) => (((current_value - old_value)/old_value)*100).toFixed(3) + "%"

export const getPageTableColumns = (compare: object, setIndividualUrl:any) => {
    if(!compare['active']){
        return [
            {
                field: 'id',
                headerName: 'S.N',
                flex: 0.1,
            },
            {
                field: 'page',
                headerName: 'Page',
                flex: 4,
                renderCell: (props: GridRenderCellParams) => (
                    <div className='wrap-text'>
                        <a rel="noreferrer" target='_blank' href={props.value}>{props.value}</a>
                        &nbsp;<FontAwesomeIcon icon={faArrowRight} className='icon-class' onClick={() => setIndividualUrl(props.value)} fontSize="medium" />
                    </div>
                )
            },
            {
                field: 'clicks',
                headerName: 'Clicks',
                flex: 1
            },
            {
                field: 'ctr',
                headerName: 'CTR',
                flex: 1
            },
            {
                field: 'impressions',
                headerName: 'Impression',
                flex: 1
            },
            {
                field: 'positions',
                headerName: 'Positions',
                flex: 1
            }
        ]
    }else if (compare['active'] === 'data'){
        return [
            {
                field: 'id',
                headerName: 'S.N',
                flex: 0.1,
            },
            {
                field: 'page',
                headerName: 'Page',
                flex: 4,
                renderCell: (props: GridRenderCellParams) => (
                    <div className='wrap-text'>
                        <a rel="noreferrer" target='_blank' href={props.value}>{props.value}</a>
                        &nbsp;<FontAwesomeIcon icon={faArrowRight} className='icon-class' onClick={() => setIndividualUrl(props.value)} fontSize="small" />
                    </div>
                )
            },
            {
                field: 'clicks',
                headerName: 'Clicks (Current)',
                flex: 1
            },
            {
                field: 'clicks_old',
                headerName: 'Clicks (Previous)',
                flex: 1
            },
            {
                field: 'ctr',
                headerName: 'CTR (Current)',
                flex: 1
            },
            {
                field: 'ctr_old',
                headerName: 'CTR (Previous)',
                flex: 1
            },
            {
                field: 'impressions',
                headerName: 'Impression (Current)',
                flex: 1
            },
            {
                field: 'impressions_old',
                headerName: 'Impression (Previous)',
                flex: 1
            },
            {
                field: 'positions',
                headerName: 'Position (Current)',
                flex: 1
            },
            {
                field: 'positions_old',
                headerName: 'Position (Previous)',
                flex: 1
            }
        ]
    }else{
        return [
            {
                field: 'id',
                headerName: 'S.N',
                flex: 0.1,
            },
            {
                field: 'page',
                headerName: 'Page',
                flex: 4,
                renderCell: (props: GridRenderCellParams) => (
                    <div className='wrap-text'>
                        <a rel="noreferrer" target='_blank' href={props.value}>{props.value}</a>
                        &nbsp;<FontAwesomeIcon icon={faArrowRight} className='icon-class' onClick={() => setIndividualUrl(props.value)} fontSize="small" />
                    </div>
                )
            },
            {
                field: 'clicks',
                headerName: 'Clicks Change %',
                flex: 1,
                renderCell: (props: GridRenderCellParams) => calculateChange(props.value, props.row.clicks_old)
            },
            {
                field: 'ctr',
                headerName: 'CTR Change %',
                flex: 1,
                renderCell: (props: GridRenderCellParams) => calculateChange(props.value, props.row.ctr_old)
            },
            {
                field: 'impressions',
                headerName: 'Impression Change %',
                flex: 1,
                renderCell: (props: GridRenderCellParams) => calculateChange(props.value, props.row.impressions_old)
            },
            {
                field: 'positions',
                headerName: 'Position Change %',
                flex: 1,
                renderCell: (props: GridRenderCellParams) => calculateChange(props.value, props.row.positions_old)
            }
        ]
    }
}

export const getQueryTableColumns = (compare: boolean) => {
    if(!compare){
        return [
            {
                field: 'id',
                headerName: 'S.N',
                flex: 0.1,
            },
            {
                field: 'query',
                headerName: 'Query',
                flex: 4,
            },
            {
                field: 'clicks',
                headerName: 'Clicks',
                flex: 1
            },
            {
                field: 'ctr',
                headerName: 'CTR',
                flex: 1
            },
            {
                field: 'impressions',
                headerName: 'Impression',
                flex: 1
            },
            {
                field: 'positions',
                headerName: 'Positions',
                flex: 1
            }
        ]
    }else{
        return [
            {
                field: 'id',
                headerName: 'S.N',
                flex: 0.1,
            },
            {
                field: 'query',
                headerName: 'Query',
                flex: 4
            },
            {
                field: 'clicks',
                headerName: 'Clicks (Current)',
                flex: 1
            },
            {
                field: 'clicks_old',
                headerName: 'Clicks (Previous)',
                flex: 1
            },
            {
                field: 'ctr',
                headerName: 'CTR (Current)',
                flex: 1
            },
            {
                field: 'ctr_old',
                headerName: 'CTR (Previous)',
                flex: 1
            },
            {
                field: 'impressions',
                headerName: 'Impression (Current)',
                flex: 1
            },
            {
                field: 'impressions_old',
                headerName: 'Impression (Previous)',
                flex: 1
            },
            {
                field: 'positions',
                headerName: 'Position (Current)',
                flex: 1
            },
            {
                field: 'positions_old',
                headerName: 'Position (Previous)',
                flex: 1
            }
        ]
    }
}


export const GscPageTable = (individualSiteData, columns, propFilterDays, compare, setCompare) => {

    const handleGraphOptionChange = (key) => {
        if(compare['active'] === key){
            setCompare({active: ''})
        }else{
            setCompare({active: key})
        }
    }

    return(
        <div>
            <div className='graph-view-options'>
                <div className='graph-view-options-item'>
                    <label>
                        Show Delta Change
                    </label>
                    <Switch className='graph-view-switch' onChange={() => handleGraphOptionChange('delta')}
                            checked={compare['active'] === 'delta'}
                    />
                </div>
                <div className='graph-view-options-item'>
                    <label>
                        Show Data Changes
                    </label>
                    <Switch className='graph-view-switch' onChange={() => handleGraphOptionChange('data')}
                            checked={compare['active'] === 'data'}
                    />
                </div>
            </div>
            <div style={{width: '100%'}}>
                <Datatable
                    rows={individualSiteData.rows.map((item:any, index:number) => {return { ...{id: index+1}, ...item}})}
                    columns={columns}
                    rowHeight={60}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'clicks', sort: 'desc' }],
                        },
                    }}
                />
            </div>
        </div>
    )
}

export const GscQueryTable = (individualSiteData, propFilterDays, compare, setCompare) => {
    return(
        <div>
            <div className='graph-view-options'>
                <div className='graph-view-options-item'>
                    <label>
                        Show Last {propFilterDays} Days vs Previous {propFilterDays} Days
                    </label>
                    <Switch className='graph-view-switch' onChange={() => setCompare(!compare)}
                            checked={compare}
                    />
                </div>
            </div>
            <div style={{width: '100%'}}>
                <Datatable
                    rows={individualSiteData.queries.map((item:any, index:number) => {return { ...{id: index+1}, ...item}})}
                    columns={getQueryTableColumns(compare)}
                    rowHeight={60}
                />
            </div>
        </div>
    )
}

const HeatMapDiv = (individualSiteData) => {
    return (
        <div className="heatmap">
            <h4>Clustered Keywords</h4>
            <hr/>
            <GSCHeatMap data={individualSiteData.heatmap_data}/>
        </div>
    )
}


export const PageAndQueryTabGenerator = (
    individualSiteData,
    site_url,
    columns,
    propFilterDays,
    compare,
    setCompare
) => {
    return {
        "Pages": GscPageTable(individualSiteData, columns, propFilterDays, compare, setCompare),
        "Queries": <TabComponent
            labelContentMap={
                TabGenerator(
                    individualSiteData,
                    site_url,
                    columns,
                    propFilterDays,
                    compare,
                    setCompare
                )}/>
    }
}

const TabGenerator = (
    individualSiteData,
    site_url,
    columns,
    propFilterDays,
    compare,
    setCompare) => {
    return {
        "Data": GscQueryTable(individualSiteData, propFilterDays, compare, setCompare),
        "Mind Map": <MindMapContainer site_url={site_url} data={individualSiteData}/>,
        "HeatMap": HeatMapDiv(individualSiteData),
    }
}
