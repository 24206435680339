import React, {useState} from "react";
import {useMutation} from "react-query";
import {getSmartArticleRequestStatus} from "../../http-request";
import {RequestStatusResponse} from "../../interface/smart_article";
import Loader from "../../components/Loader/loader";
import './request-status.scss'

function getClassForLog(log: string){
    const lowerCaseLog = log.toLowerCase()
    if (lowerCaseLog.includes('exception') || lowerCaseLog.includes('error') || lowerCaseLog.includes('failed')) {
        return 'error-log';
    } else if(lowerCaseLog.includes('completed')){
        return 'completed-log'
    } else {
        return 'info-log';
    }
}

const RequestStatus = () => {
    const [fetching, setFetching] = useState(false)
    const [requestStatusResponse, setRequestStatusResponse] = useState<RequestStatusResponse>()
    const getRequestStatusMutation = useMutation(getSmartArticleRequestStatus);
    const [requestId, setRequestId] = useState("")

    const handleSubmit = (e) => {
        setFetching(true)
        getRequestStatusMutation.mutateAsync({
            requestId: requestId
        },{
            onSuccess: (res) => {
                setFetching(false)
                setRequestStatusResponse(res)
            },
            onError: (res) => {
                setFetching(false)
            }
        })
        e.preventDefault()
    }
    return (
        <div className={'smart-article-request-status border p-4'}>
            <div className={"request-status-row-1"}>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label className="mb-2" htmlFor="request_id">REQUEST ID</label>
                        <input
                            type="text"
                            className="form-control"
                            id="request_id"
                            name="request_id"
                            onChange={(e) => setRequestId(e.target.value)}
                            value={requestId}
                        />
                    </div>
                    <div className="mt-2">
                        <button type={"submit"} className={"btn btn-success"}>Get Status</button>
                    </div>
                </form>
            </div>
            <div className={"request-status-row-2"}>
                {fetching ? (
                    <div className="fetch-details">
                        <Loader/>
                    </div>
                ) : (
                    <div className={"text-center"}>
                        {requestStatusResponse && (
                            <div>
                                <div className="status-box p-4">
                                    <p className={"status-text fw-bold mb-0"}>
                                        <span className={requestStatusResponse?.status === "completed" ? "text-success" : "text-danger"}>{requestStatusResponse?.status.toUpperCase()}</span>
                                    </p>
                                    {requestStatusResponse?.status === "completed" &&
                                        <a href={`${process.env.REACT_APP_API_ENDPOINT}smart-article/download/${requestId}`} target="_blank">Download Output</a>
                                    }
                                </div>
                                <div>
                                    <div className="card mb-3">
                                        <div className="card-header text-center">Process Log</div>
                                        <div className="card-body scrollable-log">
                                            <p className="card-text">
                                                {requestStatusResponse?.process_log?.map((log, index) => (
                                                    <span className={getClassForLog(log)} key={`log-${index}`}>{log}
                                                        <hr/></span>
                                                ))}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default RequestStatus;
