import React, {useEffect, useState} from "react";
import {getSiteList} from '../http-request'
import {useQuery} from "react-query";
import Loader from "../../../components/Loader/loader";
import {GridRenderCellParams} from "@mui/x-data-grid";
import Datatable from "../../../components/Datatable/datatable";
import IndividualSite from "../IndividiualSite/individual_site";
import './sites.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowLeft, faTable, faCodePullRequest, faClose, faRefresh} from '@fortawesome/free-solid-svg-icons'
import {VerifiedSites} from "../../../interface/search_console";
import {notifySuccess} from "../../../notify";
import {Button} from "@mui/material";
import {getData, deleteRequest} from "../../../http-request";
import {getFormattedDate, nFormatter} from "../../../components/utils";

const Site = () => {
    const [individualSite, setIndividualSite] = useState("")
    const { isFetching, data, refetch } = useQuery<VerifiedSites[], Error>('site-list',
        async () => {
            return await getSiteList();
        });
    const [siteData, setSiteData] = useState<VerifiedSites[]>([])

    useEffect(()=> {
        if(data !== undefined){
            setSiteData(data)
        }
    }, [data])

    const columns = [
        {
            field: 'id',
            headerName: 'S.N',
            flex: 0.1,
        },
        {
            field: 'site_url',
            headerName: 'Page',
            flex: 1.5,
            renderCell: (params: GridRenderCellParams) => (
                <div className='wrap-text'><a  rel="noreferrer" target='_blank' href={params.value}>{params.value}</a></div>
            ),
        },
        {
            field: 'count',
            headerName: 'Fetched Records',
            flex: 1,
            renderCell: (params: GridRenderCellParams) => nFormatter(params.value)
        },
        {
            field: 'latest_record_date',
            headerName: 'Latest Record Date',
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                params.value ? new Date(params.value).toDateString() : ''
            ),
        },
        {
            field: 'clicks',
            headerName: 'Metrics',
            flex: 2,
            renderCell: (params: GridRenderCellParams) => (
                <div className='site-metrics'>
                    <div>
                        <span>Total Clicks: </span> <b>{nFormatter(params.row.clicks)}</b>
                    </div>
                    <div>
                        <span>Total Impressions: </span> <b>{nFormatter(params.row.impressions)}</b>
                    </div>
                    <div>
                        <span>Average CTR: </span> <b>{params.row.ctr}%</b>
                    </div>
                    <div>
                        <span>Average Position: </span> <b>{nFormatter(params.row.positions)}</b>
                    </div>
                </div>
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 3,
            renderCell: (params: GridRenderCellParams) => (
                <div className={'text-center'}>
                    <Button
                        style={{ marginLeft: 10 }}
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={params.row.count === 0}
                        onClick={() => {
                            setIndividualSite(params.row.site_url)
                        }}>
                        <FontAwesomeIcon icon={faTable}/>&nbsp;Analyze
                    </Button>
                    <Button
                        style={{ marginLeft: 10 }}
                        variant="contained"
                        color="info"
                        size="small"
                        onClick={() => {
                            getData('/gsc-data/?site_url='+params.row.site_url).
                            then(value => notifySuccess({titleText: value.message}))
                        }}>
                        <FontAwesomeIcon icon={faCodePullRequest}/>&nbsp;Index Data
                    </Button>
                    <Button
                        style={{ marginLeft: 10 }}
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => {
                            deleteRequest('/gsc-data/',  {'site_url': params.row.site_url}).
                            then(value => notifySuccess({titleText: value.message}))
                        }}>
                        <FontAwesomeIcon icon={faClose}/>&nbsp;Delete
                    </Button>
                </div>
            ),
        }
    ];
    return (
        <div className={"text-center"}>
            {isFetching &&
            <Loader/>
            }
            {siteData && individualSite === "" &&
            <div style={{width: '100%'}}>
                <div style={{textAlign: 'left'}} className={'mb-2'}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                            refetch()
                        }}>
                        <FontAwesomeIcon icon={faRefresh}/>&nbsp;Refresh
                    </Button>
                </div>
                <Datatable rows={siteData.map((item:any, index:number) => {return { ...{id: index+1}, ...item}})} columns={columns}/>
            </div>
            }
            {individualSite &&
            <div>
                <div className="back-to-site">
                    <button className={"btn btn-primary"} onClick={() => setIndividualSite("")}>
                        <FontAwesomeIcon icon={faArrowLeft}/>&nbsp;Back to Sites
                    </button>
                </div>
                <IndividualSite site_url={individualSite}/>
            </div>
            }
        </div>
    )
}
export default Site;
