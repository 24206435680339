import React, {useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Button} from "@mui/material";
import './report.scss';
import PopUp from "../../components/PopUp/popup";
import {createRequest} from "../../http-request";
import {notifyError, notifySuccess} from "../../notify";
import Loader from "../../components/Loader/loader";

const createReport = (payload, name, tool_name, setName, setOpen, setLoading) => {
    setLoading(true)
    createRequest('/report/',
        {
            payload: JSON.stringify(payload),
            tool_name: tool_name,
            report_name: name
        }).then(data => {
        setLoading(false)
        if(data.success){
            setName('')
            setOpen(false)
            notifySuccess({titleText: 'Report saved successfully.'})
        }else{
            notifyError({titleText: data.message})
        }
    }).finally(() => {
        setLoading(false)
    })
}

const createReportForm = (payload, name, setName, tool_name, setOpen, loading, setLoading) =>{
    return (
        <div>
            <div className={'form-group'}>
                <label>Name</label>
                <input className={'form-control'} name={'name'} value={name}
                       onChange={(e) => setName(e.target.value)}/>
            </div>
            <div className={'form-group mt-2'} style={{height: '20px'}}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={loading}
                    onClick={() =>
                        createReport(payload, name, tool_name, setName, setOpen, setLoading)
                    }>
                    <FontAwesomeIcon icon={faPlus}/>&nbsp;Create Report
                </Button>
                <div>
                    {loading &&
                        <Loader/>
                    }
                </div>
            </div>
        </div>
    )
}

const CreateReport = (props: {data: any, tool_name: string}) => {
    const [open, setOpen] = useState(false)
    const [name, setName] = useState('')
    const [loading, setLoading] = useState(false)
    return (
        <div className={'report-create'}>
            <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                    setOpen(true)
                }}>
                <FontAwesomeIcon icon={faFile}/>&nbsp;Save as Reports
            </Button>
            {open && <PopUp
                content={createReportForm(props.data, name, setName, props.tool_name, setOpen, loading, setLoading)}
                setData={setOpen}/>
            }
        </div>
    )
}

export default CreateReport
