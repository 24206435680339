import Swal, {SweetAlertOptions} from "sweetalert2";

const notifyDefaults:SweetAlertOptions = {
    position: 'top-right',
    icon: 'info',
    showConfirmButton: false,
    timer: 3000,
    heightAuto: false,
    showCloseButton: true
}

export const notify = (props: SweetAlertOptions) =>{
    let options:SweetAlertOptions = {...notifyDefaults, ...props}
    Swal.fire(options)
}

export const notifyError = (props: SweetAlertOptions) => {
    let options:SweetAlertOptions = {...notifyDefaults, ...props, ...{icon: 'error'}}
    Swal.fire(options)
}

export const notifySuccess = (props: SweetAlertOptions) => {
    let options:SweetAlertOptions = {...notifyDefaults, ...props, ...{icon: 'success'}}
    Swal.fire(options)
}

export const userConfirmation = (title: string, text: string) =>{
    return Swal.fire({
        title: title,
        text: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        customClass: {
            actions: 'my-actions',
            cancelButton: 'order-1 right-gap',
            confirmButton: 'order-2',
            denyButton: 'order-3',
        }
    })
}
