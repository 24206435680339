import React, {useEffect, useState} from "react";
import {getQueryData} from '../http-request'
import {useQuery} from "react-query";
import Loader from "../../../components/Loader/loader";
import Datatable from "../../../components/Datatable/datatable";
import './query.scss'
import {nFormatter} from "../../../components/utils";
import {SiteQueryResponse} from "../../../interface/search_console";
import {Button} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

const Query = (props: {site_url:string, page:string, filter:number}) => {
    const [orderBy, setOrderBy] = useState("sum_clicks")
    const [order, setOrder] = useState("desc")
    const [inputQuery, setInputQuery] = useState("")
    const [filterDays, setFilterDays] = useState(props.filter)
    const { isFetching, isSuccess, data, refetch } = useQuery<SiteQueryResponse, Error>('query-data',
        async () => {
            return await getQueryData({...props, input_query: inputQuery, order_by: orderBy, order: order});
        }, {
            cacheTime: 0
        });
    const columns = [
        {
            field: 'id',
            headerName: 'S.N',
            flex: 0.1,
        },
        {
            field: 'query',
            headerName: 'Query',
            flex: 1
        },
        {
            field: 'clicks',
            headerName: 'Clicks',
            flex: 1
        },
        {
            field: 'ctr',
            headerName: 'CTR',
            flex: 1
        },
        {
            field: 'impressions',
            headerName: 'Impressions',
            flex: 1
        },
        {
            field: 'positions',
            headerName: 'Positions',
            flex: 1
        }
    ];

    const updateFilter = () => {
        refetch()
    }

    const updateFilterDays = (value) => {
        if(value){
            setFilterDays(parseInt(value))
        }else{
            setFilterDays(0)
        }
    }
    return (
        <div className={"text-center"}>
            {isFetching &&
            <Loader/>
            }
            <div className="row filter">
                <div className="form-group col-md-2">
                    <label htmlFor="filter">Last X Days</label>
                    <input id="filter" name="filter" className="form-control" type="number" value={filterDays}
                           placeholder="Last X Days" onChange={e => updateFilterDays(e.currentTarget.value)}/>
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor={'query'}>Enter Query</label>
                    <input
                        value={inputQuery}
                        placeholder="Enter Query"
                        type={'text'}
                        id={"query"}
                        name={'query'}
                        className={'form-control'}
                        onChange={(e) => setInputQuery(e.target.value)}
                    />
                </div>
                <div className="form-group col-md-2">
                    <label>Order By</label>
                    <select
                        onChange={(e) => setOrderBy(e.target.value)}
                        className={'form-control'}
                        value={orderBy}>
                        <option value={'sum_clicks'}>Clicks</option>
                        <option value={'sum_impressions'}>Impressions</option>
                        <option value={'avg_ctr'}>CTR</option>
                        <option value={'avg_positions'}>Positions</option>
                    </select>
                </div>
                <div className="form-group col-md-2">
                    <label>Order</label>
                    <select
                        onChange={(e) => setOrder(e.target.value)}
                        className={'form-control'}
                        value={order}>
                        <option value={'desc'}>DESCENDING</option>
                        <option value={'asc'}>ASCENDING</option>
                    </select>
                </div>
                <div className="col-md-1">
                    <div className="form-group">
                        <label className="hide" htmlFor="search">Search</label>
                        <Button
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={() => {
                                updateFilter()
                            }}>
                            <FontAwesomeIcon icon={faSearch}/>&nbsp;Update
                        </Button>
                    </div>
                </div>
            </div>
            {isSuccess && data &&
            <div style={{width: '100%'}}>
                <div className={'text-center p-3'}>
                    { inputQuery ? inputQuery :
                    <a href={inputQuery || props.page} rel="noreferrer" target='_blank'>{props.page}</a>
                    }
                </div>
                <div className={'metrics'}>
                    <div className={'clicks'}>
                        <span>Total Clicks<br/><b>{nFormatter(data.metrics.clicks)}</b></span>
                    </div>
                    <div className={'impressions'}>
                        <span>Total Impressions<br/><b>{nFormatter(data.metrics.impressions)}</b></span>
                    </div>
                    <div className={'ctr'}>
                        <span>Average CTR<br/><b>{data.metrics.ctr}%</b></span>
                    </div>
                    <div className={'positions'}>
                        <span>Average Position<br/><b>{nFormatter(data.metrics.positions)}</b></span>
                    </div>
                </div>
                <Datatable
                    rows={data.rows.map((item:any, index:number) => {return { ...{id: index+1}, ...item}})}
                    columns={columns}
                    rowHeight={60}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'clicks', sort: 'desc' }],
                        },
                    }}
                />
            </div>
            }
        </div>
    )
}
export default Query;
