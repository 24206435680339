import React, {useEffect, useState} from "react";
import {getSiteData, exportFile} from '../http-request'
import {useQuery} from "react-query";
import Loader from "../../../components/Loader/loader";
import Query from "../Query/query";
import './individual_site.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSearch, faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import {SCIndividualSiteResponse} from "../../../interface/search_console";
import {getPageTableColumns, PageAndQueryTabGenerator} from "./individual_site_helper";
import CreateReport from "../../Report/create_report";
import {Button} from "@mui/material";
import TabComponent from "../../../components/Tab/tab";
import {nFormatter} from "../../../components/utils";
import Collapsible from "react-collapsible";
import BulkSearch from "../BulkSearch/bulk-search";

const IndividualSite = (props) => {
    const [isReport, setIsReport] = useState(false)
    const [filterDays, setFilterDays] = useState("90")
    const [compare, setCompare] = useState({})
    const [propFilterDays, setPropFilterDays] = useState(90)
    const [individualSiteData, setIndividualSiteData] = useState<SCIndividualSiteResponse>()
    const [reportSiteUrl, setReportSiteUrl] = useState("")
    const [orderBy, setOrderBy] = useState("sum_clicks")
    const [order, setOrder] = useState("desc")
    const [inputPage, setInputPage] = useState("")
    const { data, refetch, isFetching} = useQuery<SCIndividualSiteResponse, Error>('individual-site-data',
        async () => {
            return await getSiteData({
                site_url: props['site_url'],
                filter:parseInt(filterDays),
                input_page: inputPage,
                order_by: orderBy,
                order: order,
            });
        }, {
            enabled: props.site_url ? true: false,
        });
    const [individualUrl, setIndividualUrl] = useState("")


    const updateFilter = () => {
        refetch()
        setPropFilterDays(parseInt(filterDays))
    }

    const updateFilterDays = (value) => {
        setFilterDays(value)
    }

    useEffect(() => {
        if(sessionStorage.getItem('report')){
            let reportData = JSON.parse(sessionStorage.getItem('report') || '{}')
            setIndividualSiteData(reportData['data'])
            setReportSiteUrl(reportData['site_url'])
            sessionStorage.removeItem('report')
            setIsReport(true)
        }else{
            if(!isFetching && data !== undefined && individualUrl === ''){
                setIndividualSiteData(data)
            }
        }
    }, [data, individualUrl, isFetching])

    const columns = getPageTableColumns(compare, setIndividualUrl)

    return (
        <div className={"text-center individual-site"}>
            {isFetching &&
            <Loader/>
            }
            {!isReport && individualUrl === '' && <div className="row filter">
                <div className={'col-md-12'}>
                    <Collapsible className={'bulk-search-collapse'} trigger="Bulk Search" open={false}>
                        <BulkSearch site_url={props.site_url}/>
                    </Collapsible>
                </div>
                <div className="form-group col-md-2">
                    <label htmlFor="filter">Last X Days</label>
                    <input id="filter" name="filter" className="form-control" type="text" value={filterDays}
                           placeholder="Last X Days" onChange={e => updateFilterDays(e.currentTarget.value)}/>
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor={'page_query'}>Enter Page</label>
                    <input
                        value={inputPage}
                        placeholder="Enter Page URL"
                        type={'text'}
                        id={"page_query"}
                        name={'page_query'}
                        className={'form-control'}
                        onChange={(e) => setInputPage(e.target.value)}
                    />
                </div>
                <div className="form-group col-md-2">
                    <label>Order By</label>
                    <select
                        onChange={(e) => setOrderBy(e.target.value)}
                        className={'form-control'}
                        value={orderBy}>
                        <option value={'sum_clicks'}>Clicks</option>
                        <option value={'sum_impressions'}>Impressions</option>
                        <option value={'avg_ctr'}>CTR</option>
                        <option value={'avg_positions'}>Positions</option>
                    </select>
                </div>
                <div className="form-group col-md-2">
                    <label>Order</label>
                    <select
                        onChange={(e) => setOrder(e.target.value)}
                        className={'form-control'}
                        value={order}>
                        <option value={'desc'}>DESCENDING</option>
                        <option value={'asc'}>ASCENDING</option>
                    </select>
                </div>
                <div className="col-md-1">
                    <div className="form-group">
                        <label className="hide" htmlFor="search">Search</label>
                        <Button
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={() => {
                                updateFilter()
                            }}>
                            <FontAwesomeIcon icon={faSearch}/>&nbsp;Update
                        </Button>
                    </div>
                </div>
                <div className={'col-md-2'}>
                    <div className="form-group">
                        <label className="hide">Create Report</label>
                        <CreateReport data={{'data': individualSiteData, 'site_url': props.site_url}} tool_name={'Search Console'}/>
                    </div>
                </div>
            </div>
            }
            {!isFetching && individualSiteData && individualUrl === "" &&
            <div>
                { props.site_url &&
                <div className={'text-center pb-3'}>
                    <a href={inputPage || props.site_url} rel="noreferrer" target='_blank'>{inputPage || props.site_url}</a>
                </div>
                }
                <div className={'metrics'}>
                    <div className={'clicks'}>
                        <span>Total Clicks<br/><b>{nFormatter(individualSiteData.metrics.clicks)}</b></span>
                    </div>
                    <div className={'impressions'}>
                        <span>Total Impressions<br/><b>{nFormatter(individualSiteData.metrics.impressions)}</b></span>
                    </div>
                    <div className={'ctr'}>
                        <span>Average CTR<br/><b>{individualSiteData.metrics.ctr}%</b></span>
                    </div>
                    <div className={'positions'}>
                        <span>Average Position<br/><b>{nFormatter(individualSiteData.metrics.positions)}</b></span>
                    </div>
                </div>
                <TabComponent labelContentMap={PageAndQueryTabGenerator(
                    individualSiteData,
                    props.site_url,
                    columns,
                    propFilterDays,
                    compare,
                    setCompare,
                )}/>
            </div>
            }
            {individualUrl &&
            <div>
                <div className="back-to-page">
                    <button className={"btn btn-danger"} onClick={() => setIndividualUrl("")}>
                        <FontAwesomeIcon icon={faArrowLeft}/>&nbsp;Back to Individual Page Data
                    </button>
                </div>
                <Query site_url={props.site_url || reportSiteUrl} page={individualUrl} filter={propFilterDays}/>
            </div>
            }
        </div>
    )
}
export default IndividualSite;
