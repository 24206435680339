import React, {useState} from 'react';
import {Field, FieldArray, Formik} from 'formik';
import { useMutation } from 'react-query';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear, faPen, faPlus} from "@fortawesome/free-solid-svg-icons";
import {postRequest, updateRequest} from "../../http-request";
import {User} from "../../interface/users";
import {Button} from "@mui/material";
import PopUp from "../../components/PopUp/popup";
import './user-form.scss'
import {notify} from "../../notify";

const allPermissions: string[] = [
    'chat-gpt',
    'claude-ai',
    'interlinking',
    'pagerank',
    'reports',
    'search-console',
    'seo-change-tracking',
    'sifter',
    'smart-article',
    'users',
    'vision'
]


const userForm = (user, setFetching, createOrUpdateUserMutation, refetch, setOpen) => {
    return (
        <div className={'add-user-form-div'}>
            <Formik
                initialValues={{
                    username: user?.username || '',
                    password: user?.password || '',
                    active: user?.active || true,
                    permissions: user?.permissions || []
                }}
                validate={values => {
                    const errors: { [index: string]: any } = {}
                    if (!values.username) {
                        errors.prompt = 'Please enter the username';
                    }
                    if (!values.password && !user) {
                        errors.password = 'Please enter the password';
                    }
                    return errors;
                }}
                onSubmit={(values) => {
                    const submit = async () => {
                        setFetching(true)
                        createOrUpdateUserMutation.mutateAsync(
                            {
                                user: values
                            },
                            {
                                onSuccess: (res) => {
                                    setFetching(false)
                                    notify({icon: res.success ? 'success': 'error', titleText: res.message} )
                                    if (res.success){
                                        refetch()
                                        setOpen(false)
                                    }
                                },
                                onError: (res) => {
                                    setFetching(false)
                                }
                            })
                    }
                    submit()
                }}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit} className="border p-4">
                        <div className={"row"}>
                            <h3 className={'text-center'}>User</h3>
                            <hr></hr>
                        </div>
                        <div className="row mt-2" style={{alignItems: 'center'}}>
                            <div className="col-md-12">
                                <div className={'row'}>
                                    <div className={'col-md-6'}>
                                        <label htmlFor="username">Username</label>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="username"
                                    name="username"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.username}
                                    style={{width: '500px'}}
                                />
                                <span
                                    className='input-error'>{errors.username && touched.username && errors.username}</span>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <div className={'row'}>
                                    <div className={'col-md-6'}>
                                        <label htmlFor="password">Password</label>
                                    </div>
                                </div>
                                <input
                                    className="form-control"
                                    type="password"
                                    id="password"
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    style={{width: '500px'}}
                                />
                                <span
                                    className='input-error'>{errors.password && touched.password && errors.password}</span>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-md-12'>
                                <div className={'row'}>
                                    <div className={'col-md-6'}>
                                        <label htmlFor="active">User Status</label>
                                    </div>
                                </div>
                                <label>
                                    <Field type="checkbox" name="active"/>
                                    <span style={{
                                        flex: '1',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        marginLeft: '10px'
                                    }}>
                                                        ACTIVE
                                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className="col-md-12">
                                <div className={'row'}>
                                    <div className={'col-md-6'}>
                                        <label htmlFor="permissions">Permissions</label>
                                    </div>
                                </div>
                                <FieldArray name="permissions">
                                    {({push, remove}) => (
                                        <div style={{width: '500px'}}>
                                            {allPermissions.map((permission, index) => (
                                                <label key={index} style={{
                                                    display: 'block',
                                                    width: '100%',
                                                    padding: '5px 0',
                                                }}>
                                                    <Field
                                                        type="checkbox"
                                                        name="permissions"
                                                        value={permission}
                                                    />
                                                    <span style={{
                                                        flex: '1',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        marginLeft: '10px'
                                                    }}>
                                                        {permission.toUpperCase()}
                                                    </span>
                                                </label>
                                            ))}
                                        </div>
                                    )}
                                </FieldArray>
                            </div>
                        </div>
                        <div className="text-center mt-4">
                            <button
                                disabled={createOrUpdateUserMutation.isLoading}
                                className="btn m-1"
                                style={{backgroundColor: 'coral'}}
                                type="submit"
                            >
                                <FontAwesomeIcon icon={faGear}/> {user ? 'Update' : 'Save'}
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}

const UserForm = (props: { user?: User, refetch: any, setFetching: any }) => {
    const [open, setOpen] = useState(false)
    const {user, setFetching, refetch} = props

    const createOrUpdateUser = async (props: { user: User }) => {
        if (user?.id) {
            return await updateRequest(`/users`, props.user)
        } else {
            return await postRequest('/users', props.user)
        }
    }

    const createOrUpdateUserMutation = useMutation(createOrUpdateUser);

    return (
        <div className={`add-user ${user ? 'edit-page' : ''}`}>
            <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                    setOpen(true)
                }}>
                {user ? (
                    <span>
                        <FontAwesomeIcon icon={faPen}/>&nbsp;Edit
                    </span>
                ) : (
                    <span>
                        <FontAwesomeIcon icon={faPlus}/>&nbsp;Add User
                    </span>
                )}
            </Button>
            {open && <PopUp
                content={userForm(user, setFetching, createOrUpdateUserMutation, refetch, setOpen)}
                setData={setOpen}/>
            }
        </div>
    )
}

export default UserForm