import React from "react";
import {WebsiteActivity} from "../../../interface/seo-change-tracking";
import {createLink, getTableHeader, getTimeWithoutTimezone} from "../../../components/utils";

export const LinkRedirected = (linkActivity: WebsiteActivity) => {
    const activity = linkActivity.activity
    return (
        <div>
            {getTableHeader(linkActivity.id, linkActivity.created_at)}
            <p className={'link-redirected'}>Page Redirected from {createLink(activity.source, activity.source)} <b>to</b>  {createLink(activity.destination, activity.destination)}</p>
        </div>
    )
}

export const LinkAdded = (linkActivity: WebsiteActivity) => {
    const activity = linkActivity.activity
    return (
        <div>
            {getTableHeader(linkActivity.id, linkActivity.created_at)}
            <p className={'link-added'}>New Page Added: {createLink(activity.link, activity.link)}</p>
        </div>
    )
}

export const LinkRemoved = (linkActivity: WebsiteActivity) => {
    const activity = linkActivity.activity
    return (
        <div>
            {getTableHeader(linkActivity.id, linkActivity.created_at)}
            <p className={'link-removed'}>Page Removed: {createLink(activity.link, activity.link)}</p>
        </div>
    )
}


export const LinkError = (linkActivity: WebsiteActivity) => {
    const activity = linkActivity.activity
    return (
        <div>
            {getTableHeader(linkActivity.id, linkActivity.created_at)}
            <p className={'link-error'}>
                <b>Link:</b> {createLink(activity.link, activity.link)}<br/>
                <b>Status Code:</b> {activity.code}<br/>
                <b>Error:</b> {activity.error}<br/>
            </p>
        </div>
    )
}
