import React, {useEffect, useState} from 'react'
import HeatMap from "react-heatmap-grid";
import "./heatmap.scss"
import {SCIndividualSiteResponseHeatMapData} from "../../interface/search_console";
import PopUp from "../PopUp/popup";

const getXLabelsNumber = (key: string) => {
    return parseInt(sessionStorage.getItem(key) || "0")
}

const setDataInSessionStorage = (data: SCIndividualSiteResponseHeatMapData) => {
    data.x_labels.forEach(value => {
        sessionStorage.setItem(value+"-max", data.max[value])
        sessionStorage.setItem(value+"-min", data.min[value])
    })
    sessionStorage.setItem("cluster_url_grouped_data", JSON.stringify(data.cluster_url_grouped_data))
}

const getClickedClusterData = (clusterName: string) => {
    let data = JSON.parse(sessionStorage.getItem('cluster_url_grouped_data') || "{}")[clusterName]
    return (
        <table className={'table table-bordered cluster-group-table'}>
            <caption className={'text-center'}>{clusterName}</caption>
            <thead>
            <tr>
                <th>URL</th>
                <th>Keywords</th>
            </tr>
            </thead>
            <tbody>
            {Object.keys(data).map(value => {
                return (
                    <tr key={value}>
                        <td width={'60%'}><a href={value} target='_blank'>{value}</a></td>
                        <td width={'40%'}>
                            {data[value].map(keyword => <li key={keyword}>{keyword}</li>)}
                        </td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}

const GSCHeatMap = (props: {data: SCIndividualSiteResponseHeatMapData}) => {
    const [clickedData, setClickedData] = useState<any>()
    const [filterSite, setFilterSiteUrl] = useState("")
    const [processedData, setProcessedData] = useState({data: [], xLabels: [], yLabels: [] as string[]})
    setDataInSessionStorage(props.data)
    useEffect(() => {
        if(filterSite.length > 10){
            let yLabels = Object.keys(props.data.cluster_url_grouped_data).slice()
            let data = props.data.data.slice()
            let indexToRemove:number[] = []
            for(let i=0;i<yLabels.length;i++){
                const urls = Object.keys(props.data.cluster_url_grouped_data[yLabels[i]])
                if(!(urls.includes(filterSite))){
                    indexToRemove.push(i)
                }
            }
            for (let i = indexToRemove.length -1; i >= 0; i--){
                yLabels.splice(indexToRemove[i],1);
                data.splice(indexToRemove[i],1);
            }
            setProcessedData({
                ...processedData,
                data: data,
                yLabels: yLabels
            })
        }else{
            setProcessedData({
                ...processedData,
                data: props.data.data,
                xLabels: props.data.x_labels,
                yLabels: Object.keys(props.data.cluster_url_grouped_data)
            })
        }
        return () => {
            sessionStorage.removeItem('cluster_url_grouped_data')
        };
    }, [filterSite, props.data])

    const yLabels = processedData.yLabels.map((value, index) => {
        return (
            <div
                className='text-primary y-labels'
                onClick={() => setClickedData(getClickedClusterData(value))}>
                {value}
            </div>
        )
    })

    return (
        <div>
            <div className={'row'}>
                <div className={'col-md-4'}>
                    <label>Filter by Site URL</label>
                    <input
                        className={'form-control'}
                        type={'text'}
                        value={filterSite}
                        onChange={(e) => setFilterSiteUrl(e.target.value)}/>
                </div>
            </div>
            <HeatMap
                xLabels={processedData.xLabels}
                yLabels={yLabels}
                data={processedData.data}
                xLabelsLocation={"top"}
                xLabelWidth={100}
                yLabelTextAlign={"left"}
                yLabelWidth={400}
                height={50}
                cellStyle={(background, value, min, max, data, x, y) => ({
                    background: 'transparent',
                })}
                cellRender={(value, x, y) => {
                    const xMax = getXLabelsNumber(x+"-max")
                    const xMin = getXLabelsNumber(x+"-min")
                    return <div className='heatmap-cell' style={{backgroundColor: `rgba(255, 0, 0, ${1 - (xMax - value) / (xMax - xMin)})`}}>
                        <div>{value}</div>
                    </div>
                }}
            />
            {clickedData !== undefined && <PopUp content={clickedData} setData={setClickedData}/>}
        </div>
    );
}

export default GSCHeatMap
