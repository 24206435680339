import React, {useState} from "react";
import {CreateResponse} from "../../interface/smart_article";
import {useMutation} from "react-query";
import {createSmartArticleRequestWithFile} from "../../http-request";
import {Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/Loader/loader";

const FileInput = () => {
    const [fetching, setFetching] = useState(false)
    const [createResponse, setCreateResponse] = useState<CreateResponse>()
    const createRequestMutation = useMutation(createSmartArticleRequestWithFile);

    return (
        <div className={'smart-article'}>
            <div className={'smart-article-form'}>
                <Formik
                    initialValues={{ input_file: ''}}
                    validate={values => {
                        const errors : {[index: string]:any} = {}
                        if (!values.input_file) {
                            errors.input_file = 'please select the input file';
                        }
                        return errors;
                    }}
                    onSubmit={(values) => {
                        const submit = async () => {
                            setFetching(true)
                            createRequestMutation.mutateAsync({input_file: values.input_file},
                                {
                                    onSuccess: (res) => {
                                        setFetching(false)
                                        setCreateResponse(res)
                                    }
                                })
                        }
                        submit()
                    }}>
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit} className="border p-4">
                            <div className="row">
                                <div className={'offset-md-3 col-md-6'}>
                                    <div className='form-group text-center'>
                                        <input
                                            type="file"
                                            className="form-control"
                                            name="input_file"
                                            id="input_file"
                                            accept="text/csv"
                                            onChange={(event) => {
                                                const files = event.currentTarget.files;
                                                if (files !== null) {
                                                    setFieldValue("input_file", files[0]);
                                                }
                                            }}
                                            onBlur={handleBlur}
                                        />
                                        <span
                                            className='input-error'>{errors.input_file && touched.input_file && errors.input_file}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center mt-4">
                                    <button disabled={createRequestMutation.isLoading}
                                            className='btn btn-info search-sitemap-urls' type="submit">
                                        <FontAwesomeIcon icon={faGear}/> Process
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            {fetching ? (
                <div className="fetch-details">
                    <Loader/>
                </div>
            ) : (
                <div>
                    <div className="result">
                        {createResponse !== undefined &&
                            <div style={{width: '100%'}}>
                                <div className="container mt-4 border p-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className={"text-center"}>
                                                <div className="card mb-3">
                                                    <div className="card-header">Status Request ID</div>
                                                    <div className="card-body">
                                                        <p className="card-text">
                                                            To check the status of your request, please use the following request ID:
                                                            <br/><span
                                                            className="fw-bold">{createResponse.request_id}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
            }
        </div>
    )
}

export default FileInput