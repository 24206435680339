import React from "react";
import './best_guess_label.scss';


const BestGuessLabels = (props: {labels: []}) => {
    const {labels} = props
    return (
        <ul className="list-group text-center" id="best-guess-labels">
            {labels.map((item, index) => {
                return (<li key={index} className="list-group-item">{item}</li>)
            })}
        </ul>
    )
}

export default BestGuessLabels;
