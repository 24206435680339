import {apiClient} from "./http-common";
import {VisionApi} from "./interface/vision";
import {SiteMapProps} from "./interface/interlinking";
import {LoginRequestProps} from "./interface/login";
import {NLPApiProps} from "./interface/nlp";
import {PagerankProps} from "./interface/pagerank";
import {WebsiteActivityRequestProps} from "./interface/seo-change-tracking";
import {OpenAIProps} from "./interface/openai";
import {CreateProps as SmartArticleCreateProps} from "./interface/smart_article";
import {ClaudeAIBulkCreateRequest, ClaudeAICreateRequest} from "./interface/claude-ai";
import {ChatGPTRewriteCreateRequest} from "./interface/chat-gpt-rewrite";

export const getVisionAPIResult = async (visionProps: VisionApi) => {
    if(visionProps.image_file){
        let formData = new FormData();
        formData.append("image_file", visionProps.image_file);
        return await apiClient.post('/vision/search/', formData, {headers: {"Content-type": "multipart/form-data"}});
    }
    return await apiClient.post('/vision/search/', visionProps);
};

export const startSiteScrapping = async (siteMapProps: SiteMapProps) => {
    if(siteMapProps.input_file){
        let formData = new FormData();
        formData.append("input_file", siteMapProps.input_file);
        return await apiClient.post('/interlinking/site-maps/', formData, {headers: {"Content-type": "multipart/form-data"}});
    }
    return await apiClient.post('/interlinking/site-maps/', siteMapProps);
}

export const getScrappingDetail = async (siteMapProps: SiteMapProps) => {
    return await apiClient.get('/interlinking/site-maps-details/?site_url='+siteMapProps.site_url);
}

export const loginRequest = async (loginProps: LoginRequestProps) => {
    return await apiClient.post('/login', loginProps);
}

export const createRequest = async (url, data) => {
    const response = await apiClient.post(url, data)
    return response.data
}

export const updateRequest = async (url, data) => {
    const response = await apiClient.put(url, data)
    return response.data
}

export const postRequest = async (url, data) => {
    const response = await apiClient.post(url, data)
    return response.data
}

export const getData = async (url) => {
    const response = await apiClient.get(url)
    return response.data
}

export const deleteRequest = async (url, data) => {
    const response = await apiClient.delete(url, {data: data})
    return response.data
}

export const getNLPAPIResult = async (visionProps: NLPApiProps) => {
    const response =  await apiClient.post('/nlp/search/', visionProps)
    return response.data;
};

export const getPageRankResult = async (pagerankProps: PagerankProps) => {
    let formData = new FormData();
    formData.append("outlink_file", pagerankProps.outlink_file);
    formData.append("internal_all_file", pagerankProps.internal_all_file);
    formData.append("redirect_and_canonical_chains_file", pagerankProps.redirect_and_canonical_chains_file);
    return await apiClient.post('/page-rank/', formData, {headers: {"Content-type": "multipart/form-data"}});
}

export const getOpenAIResult = async (openAIProps: OpenAIProps) => {
    const response = await apiClient.post('/chat-gpt', openAIProps)
    return response.data;
}

export const createClaudeAI = async (claudeAICreateRequest: ClaudeAICreateRequest) => {
    const response = await apiClient.post('/claude-ai', claudeAICreateRequest)
    return response.data;
}

export const createClaudeAIRewrite = async (claudeAICreateRequest: ClaudeAICreateRequest) => {
    const response = await apiClient.post('/claude-ai-rewrite', claudeAICreateRequest)
    return response.data;
}

export const createClaudeAIBulk = async (claudeAIBulkCreateRequest: ClaudeAIBulkCreateRequest) => {
    const response = await apiClient.post('/claude-ai-bulk', claudeAIBulkCreateRequest)
    return response.data;
}

export const createChatGPTRewrite = async (chatGPTRewriteCreateRequest: ChatGPTRewriteCreateRequest) => {
    const response = await apiClient.post('/chat-gpt-rewrite', chatGPTRewriteCreateRequest)
    return response.data;
}

export const getOpenAIPreview = async (openAIProps: OpenAIProps) => {
    const response = await apiClient.post('/openai/input-token-count/', openAIProps)
    return response.data;
}

export const createSmartArticleRequest = async (createProps: SmartArticleCreateProps)=> {
    const response = await apiClient.post('/smart-article', createProps)
    return response.data;
}

export const getSmartArticleRequestStatus = async (props: {requestId: string}) => {
    const response = await apiClient.get(`/smart-article/${props.requestId}`)
    return response.data;
}

export const createSmartArticleRequestWithFile = async (props: {input_file: any}) => {
    let formData = new FormData();
    formData.append("input_file", props.input_file);
    const response =  await apiClient.post('/smart-article', formData, {headers: {"Content-type": "multipart/form-data"}});
    return response.data;
}

export const getWebsiteActivities = async (websiteActivityRequestProps:WebsiteActivityRequestProps) => {
    const response = await apiClient.post('/seo-change-tracking/activities/', websiteActivityRequestProps)
    return response.data;
}

export const getSifterResult = async  (data: { keyword_file: any, keywords: string }) => {
    if(data.keyword_file){
        let formData = new FormData();
        formData.append("keyword_file", data.keyword_file);
        return await apiClient.post('/sifter/', formData, {headers: {"Content-type": "multipart/form-data"}});
    }else{
        return await apiClient.post('/sifter/', { keywords: data.keywords });
    }
}
