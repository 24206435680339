import React from "react";
import User from "./User/user";
import Site from "./Site/site";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import './search-console.scss'

const SearchConsole = () => {
    const userState = useSelector((state: RootState) => state.user)
    return (
        <div>
            <User/>
            {userState.loggedIn &&
            <Site/>
            }
        </div>
    )
}

export default SearchConsole
