import React, {useEffect, useState} from "react";
import {getDateTimeWithoutTimezone} from "../../components/utils";
import {Button} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import {useQuery} from "react-query";
import {getData} from "../../http-request";
import {User, UsersList} from "../../interface/users";
import Loader from "../../components/Loader/loader";
import Datatable from "../../components/Datatable/datatable";
import UserForm from "./user-form";


const columns = (setLoading, refetch) => [
    {
        field: 'id',
        headerName: 'S.N',
        flex: 0.1,
    },
    {
        field: 'username',
        headerName: 'Username',
        flex: 2,
    },
    {
        field: 'active',
        headerName: 'Active',
        flex: 1,
        renderCell: (params => params.value === true ? 'YES': 'NO')
    },
    {
        field: 'permissions',
        headerName: 'Permissions',
        flex: 4,
        renderCell: params => {
            return (
                <ul>
                    {params.value.map((permission) => (
                        <li key={permission}>{permission.toUpperCase()}</li>
                    ))}
                </ul>
            );
        }
    },
    {
        field: 'created_at',
        headerName: 'Created At',
        flex: 3,
        renderCell: (params => getDateTimeWithoutTimezone(params.value))
    },
    {
        field: 'action',
        headerName: 'Action',
        flex: 1,
        renderCell: (params) => (
            <div className=''>
                <UserForm refetch={refetch} user={params.row} setFetching={setLoading}/>
            </div>
        )
    }
]

const UserList = () => {
    const { data, isFetching, refetch} = useQuery<UsersList, Error>('user-list',
        async () => {
            return await getData('/users');
        });
    const [rows, setRows] = useState<User[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(data?.users){
            setRows(data?.users)
        }
    }, [data])

    return (
        <div className={'text-center'}>
            {(isFetching || loading) &&
                <Loader/>
            }
            {!isFetching && rows &&
                <div style={{width: '100%'}}>
                    <UserForm refetch={refetch} setFetching={setLoading}/>
                    <div style={{textAlign: 'right'}} className={'mb-2'}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                                refetch()
                            }}>
                            <FontAwesomeIcon icon={faRefresh}/>&nbsp;Refresh
                        </Button>
                    </div>
                    <Datatable rows={rows.map((item: any, index: number) => {
                        return {...item, ...{id: index + 1}}
                    })} columns={columns(setLoading, refetch)} rowHeight={120}/>
                </div>
            }
        </div>
    )
}

export default UserList
