import React, {useEffect, useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSave, faPen} from "@fortawesome/free-solid-svg-icons";
import {Button} from "@mui/material";
import './seo-change-tracking.scss';
import PopUp from "../../components/PopUp/popup"
import {createRequest, updateRequest} from "../../http-request"
import {notifyError, notifySuccess} from "../../notify"
import Loader from "../../components/Loader/loader"
import CreatableSelect from 'react-select/creatable'
import _ from 'loadsh'

const knowHtmlTags = ["title","h1","h2","h3","h4","h5","h6","dt","li", "p","pre", "b", "i","span","strong", "caption" ,"td","th","button","label","legend", "a"]

const urlPatternValidation = URL => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    return regex.test(URL);
}

const changeUrl = (url, setState) => {
    setState(prev => ({...prev, validWebsite: !url || urlPatternValidation(url), website: url }))
}

const createWebsite = (state, setState, setOpen, setLoading, refetch) => {
    setLoading(true)
    let method = state.create ? createRequest : updateRequest
    method('/seo-change-tracking/',
        {
            website_id: state.website_id,
            website: state.website,
            configured_elements: JSON.stringify(state.configuredElements)
        }).then(data => {
        setLoading(false)
        if(data.success){
            changeUrl('', setState)
            setOpen(false)
            if(state.create){
                notifySuccess({titleText: 'Website added successfully.'})
            }else{
                notifySuccess({titleText: 'Website updated successfully.'})
            }
            refetch()
        }else{
            notifyError({titleText: data.message})
        }
    }).finally(() => {
        setLoading(false)
    })
}

const webSiteForm = (state, setState, setOpen, loading, setLoading, refetch) =>{
    const values = state.configuredElements.map(name => ({
        value: name,
        label: name,
        key: name
    }))
    const options = _.union(state.configuredElements, knowHtmlTags).map(name => ({
        value: name,
        label: name,
        key: name
    }))
    return (
        <div className={'add-website-form-div'}>
            <div className={'form-group'}>
                <label>Website URL</label>
                <input readOnly={!state.create} style={{width: '500px'}} className={'form-control mt-2'} name={'website'} value={state.website}
                       onChange={(e) => changeUrl(e.target.value, setState)}/>
                {!state.validWebsite &&
                <span className={'error-message'}>Invalid website url</span>
                }
            </div>
            <div className={'form-group'}>
                <label>Element to Keep Track</label>
                <CreatableSelect
                    key='configure-elements-list'
                    isMulti={true}
                    onChange={state.onConfiguredElementChange}
                    options={options}
                    value={values}
                    className={'mt-2'}
                />
            </div>
            <div className={'form-group mt-4'} style={{height: '20px'}}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={loading || !state.validWebsite || !state.website || state.configuredElements.length === 0}
                    onClick={() =>
                        createWebsite(state, setState, setOpen, setLoading, refetch)
                    }>
                    <FontAwesomeIcon icon={faSave}/>&nbsp;Save
                </Button>
                <div>
                    {loading &&
                    <Loader/>
                    }
                </div>
            </div>
        </div>
    )
}

export const AddWebsite = (props: {refetch: any}) => {
    const [open, setOpen] = useState(false)
    const [state, setState] = useState({})
    const [loading, setLoading] = useState(false)

    const onConfiguredElementChange = (newValue, actionMeta) => {
        setState(prev => ({...prev, configuredElements: newValue.map(o => o.value) }))
    }

    useEffect(() => {
        setState({
            website: '',
            configuredElements: [],
            create: true,
            validWebsite: true,
            onConfiguredElementChange: onConfiguredElementChange
        })
    }, [open])

    return (
        <div className={'add-website'}>
            <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                    setOpen(true)
                }}>
                <FontAwesomeIcon icon={faPlus}/>&nbsp;Add Website
            </Button>
            {open && <PopUp
                content={webSiteForm(
                    state,
                    setState,
                    setOpen,
                    loading,
                    setLoading,
                    props.refetch,
                )}
                setData={setOpen}/>
            }
        </div>
    )
}

export const EditWebsite = (props: {refetch: any, elements: [], website_id: number, website: string}) => {
    const [open, setOpen] = useState(false)
    const [state, setState] = useState({})
    const [loading, setLoading] = useState(false)

    const onConfiguredElementChange = (newValue, actionMeta) => {
        setState(prev => ({...prev, configuredElements: newValue.map(o => o.value) }))
    }

    useEffect(() => {
        setState({
            website: props.website,
            website_id: props.website_id,
            configuredElements: props.elements,
            create: false,
            validWebsite: true,
            onConfiguredElementChange: onConfiguredElementChange
        })
    }, [open])

    return (
        <div className={'add-website'}>
            <Button
                variant="text"
                color="primary"
                size="small"
                onClick={() => {
                    setOpen(true)
                }}>
                <FontAwesomeIcon icon={faPen}/>&nbsp;Edit
            </Button>
            {open && <PopUp
                content={webSiteForm(
                    state,
                    setState,
                    setOpen,
                    loading,
                    setLoading,
                    props.refetch,
                )}
                setData={setOpen}/>
            }
        </div>
    )
}
