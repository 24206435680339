import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear} from "@fortawesome/free-solid-svg-icons";
import {Formik} from "formik";
import {PagerankResponse} from "../../interface/pagerank";
import {useMutation} from "react-query";
import {getPageRankResult} from "../../http-request";
import Loader from "../../components/Loader/loader";
import Datatable from "../../components/Datatable/datatable";
import CreateReport from "../Report/create_report";

const PageRank = () => {
    const [fetching, setFetching] = useState(false)
    const [pageRankResponse, setPageRankResponse] = useState<PagerankResponse>()
    const [isReport, setIsReport] = useState(false)
    const getPageRankResultMutation = useMutation(getPageRankResult);
    useEffect(() => {
        if(sessionStorage.getItem('report')){
            setPageRankResponse(JSON.parse(sessionStorage.getItem('report') || '{}'))
            sessionStorage.removeItem('report')
            setIsReport(true)
        }
    }, []);
    const columns = [
        {
            field: 'id',
            headerName: 'S.N',
            flex: 0.1,
        },
        {
            field: 'Address',
            headerName: 'Address',
            flex: 4
        },
        {
            field: 'Indexability',
            headerName: 'Indexability',
            flex: 1,
        },
        {
            field: 'Title 1',
            headerName: 'Title 1',
            flex: 3,
        },
        {
            field: 'Unique External Outlinks',
            headerName: 'Unique External Outlinks',
            flex: 0.7,
        },
        {
            field: 'Unique Inlinks',
            headerName: 'Unique Inlinks',
            flex: 0.7,
        },
        {
            field: 'Unique Outlinks',
            headerName: 'Unique Outlinks',
            flex: 0.7,
        },
        {
            field: 'pr_contentLinks',
            headerName: 'PR Content Links',
            flex: 1,
        },
        {
            field: 'pr_noNavigation',
            headerName: 'PR No Navigation',
            flex: 1,
        },
    ]
    return (
        <div className={'page-rank'}>
            <div className={'page-rank-form'}>
                <Formik
                    initialValues={{ outlink_file: '', internal_all_file: '', redirect_and_canonical_chains_file: ''}}
                    validate={values => {
                        const errors : {[index: string]:any} = {}
                        if (!values.outlink_file) {
                            errors.outlink_file = 'please upload all_outlinks.csv file.';
                        }
                        if (!values.internal_all_file) {
                            errors.internal_all_file = 'please upload internal_all.csv file.';
                        }
                        if(!values.redirect_and_canonical_chains_file){
                            errors.redirect_and_canonical_chains_file = 'please upload redirects_and_canonical_chains.csv file.';
                        }
                        return errors;
                    }}
                    onSubmit={(values) => {
                        const submit = async () => {
                            setFetching(true)
                            getPageRankResultMutation.mutateAsync(
                                {
                                    outlink_file: values.outlink_file,
                                    internal_all_file: values.internal_all_file,
                                    redirect_and_canonical_chains_file: values.redirect_and_canonical_chains_file,
                                },
                                {
                                    onSuccess: (res) => {
                                        setFetching(false)
                                        setPageRankResponse(res.data)
                                    },
                                    onError: (res) => {
                                        setFetching(false)
                                    }
                                })
                        }
                        submit()
                    }}>
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className='form-group col-md-4'>
                                    <label>Outlinks File</label>
                                    <input
                                        type="file"
                                        className="form-control col-md-6"
                                        name="outlink_file"
                                        id="outlink_file"
                                        accept="text/csv"
                                        onChange={(event) => {
                                            const files = event.currentTarget.files;
                                            if(files !== null){
                                                setFieldValue("outlink_file", files[0]);
                                            }
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <span className='input-error'>{errors.outlink_file && touched.outlink_file && errors.outlink_file}</span>
                                </div>
                                <div className='form-group col-md-4'>
                                    <label>Internal All File</label>
                                    <input
                                        type="file"
                                        className="form-control col-md-6"
                                        name="internal_all_file"
                                        id="internal_all_file"
                                        accept="text/csv"
                                        onChange={(event) => {
                                            const files = event.currentTarget.files;
                                            if(files !== null){
                                                setFieldValue("internal_all_file", files[0]);
                                            }
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <span className='input-error'>{errors.internal_all_file && touched.internal_all_file && errors.internal_all_file}</span>
                                </div>
                                <div className='form-group col-md-4'>
                                    <label>Redirect and Canonical Chains File</label>
                                    <input
                                        type="file"
                                        className="form-control col-md-6"
                                        name="redirect_and_canonical_chains_file"
                                        id="redirect_and_canonical_chains_file"
                                        accept="text/csv"
                                        onChange={(event) => {
                                            const files = event.currentTarget.files;
                                            if(files !== null){
                                                setFieldValue("redirect_and_canonical_chains_file", files[0]);
                                            }
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <span className='input-error'>{errors.redirect_and_canonical_chains_file && touched.redirect_and_canonical_chains_file && errors.redirect_and_canonical_chains_file}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center mt-4">
                                    <button disabled={getPageRankResultMutation.isLoading} className='btn btn-info search-sitemap-urls' type="submit">
                                        <FontAwesomeIcon icon={faGear}/> Process
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            { fetching ? (
                <div className="fetch-details">
                    <Loader/>
                </div>
            ) : (
                <div className="result">
                    { pageRankResponse !== undefined &&
                    <div style={{width: '100%'}}>
                        {!isReport &&
                        <div>
                            <CreateReport data={pageRankResponse} tool_name={'Pagerank'}/>
                        </div>
                        }
                        <Datatable rows={pageRankResponse.rows.map((item:any, index:number) => {return { ...{id: index+1}, ...item}})} columns={columns}/>
                    </div>
                    }
                </div>
            )
            }
        </div>
    )
}

export default PageRank
