import { configureStore } from '@reduxjs/toolkit'
import userReducer from './features/Authentication/userSlice'


export const store = configureStore({
    reducer: {
        user: userReducer
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch


store.subscribe(() => {
    const user = store.getState().user
    localStorage.setItem('loggedIn', user.loggedIn.toString())
    localStorage.setItem('token', user.token)
    localStorage.setItem('applicationLoggedIn', user.applicationLoggedIn.toString())
    localStorage.setItem('applicationToken', user.applicationToken)
    localStorage.setItem('permissions', JSON.stringify(user.permissions))
})
