import React, {useEffect, useState} from "react";
import './activities.scss'
import {useQuery} from "react-query";
import {WebsiteActivity, WebsiteActivityList} from "../../../interface/seo-change-tracking";
import {getWebsiteActivities} from "../../../http-request";
import Loader from "../../../components/Loader/loader";
import {getFormattedDate} from "../../../components/utils";
import {ActivityConstants} from "./constants";
import {LinkAdded, LinkRedirected, LinkRemoved, LinkError} from "./link_activities";
import {ElementAdded, ElementModified, ElementRemoved} from "./element_activities";
import ReactPaginate from "react-paginate";
import {Button} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import Switch from "@mui/material/Switch";

const CategorizedLinkActivity = (linkActivities: WebsiteActivity[]) => {
    let firstDate = getFormattedDate(linkActivities?.[0]?.created_at || "")
    return linkActivities.map((linkActivity, index) => {
        let recordDate = getFormattedDate(linkActivity.created_at)
        let changeHeader = firstDate !== recordDate || index === 0
        if(changeHeader){
            firstDate = recordDate
        }
        return (
            <div key={'link-activity-'+index}>
                { changeHeader && <h3><hr/>{recordDate}</h3> }
                <div className="box">
                    <div className="container">
                        <div className="cards">
                            <div className="card">
                                { linkActivity.activity.type === ActivityConstants.LINK_REDIRECTED && LinkRedirected(linkActivity)  }
                                { linkActivity.activity.type === ActivityConstants.LINK_ADDED && LinkAdded(linkActivity)  }
                                { linkActivity.activity.type === ActivityConstants.LINK_REMOVED && LinkRemoved(linkActivity)  }
                                { linkActivity.activity.type === ActivityConstants.LINK_ERROR && LinkError(linkActivity)  }
                                { linkActivity.activity.type === ActivityConstants.ELEMENT_ADDED && ElementAdded(linkActivity)  }
                                { linkActivity.activity.type === ActivityConstants.ELEMENT_REMOVED && ElementRemoved(linkActivity)  }
                                { linkActivity.activity.type === ActivityConstants.ELEMENT_MODIFIED && ElementModified(linkActivity)  }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

}

const Activities = (props: {website_id: number}) => {
    const [state, setState] = useState({page: 0, order_by: 'updated_at', order: 'desc', source: ""})
    const { data, isFetching, refetch} = useQuery<WebsiteActivityList, Error>('seo-change-tracking-website-activities',
        async () => {
            return await getWebsiteActivities({
                website_id: props.website_id,
                ...state
            });
        });

    useEffect(() => {
        refetch()
    }, [state])

    const changeStateValue = (key, value) => {
        setState(prev => ({...prev, [key]: value}))
    }

    const handlePageClick = (data) => {
        changeStateValue('page', data.selected)
    }

    const handleSourceChange = (newSource) => {
        if(state.source === newSource){
            changeStateValue('source', '')
        }else{
            changeStateValue('source', newSource)
        }
    }

    return (
        <div>
            {isFetching &&
            <Loader/>
            }
            {!isFetching && data && data.activities &&
            <div style={{width: '100%'}}>
                <div className={'activities-controller mt-2'}>
                    <div className='graph-view-options'>
                        <div className='graph-view-options-item'>
                            <label>
                                Links
                            </label>
                            <Switch className='graph-view-switch' onChange={() => handleSourceChange("LINK")}
                                    checked={state.source === "LINK"}
                            />
                        </div>
                        <div className='graph-view-options-item'>
                            <label>
                                Elements
                            </label>
                            <Switch className='graph-view-switch' onChange={() => handleSourceChange("ELEMENT")}
                                    checked={state.source === "ELEMENT"}
                            />
                        </div>
                        <div className='graph-view-options-item'>
                            <div style={{textAlign: 'right'}} className={'mb-2'}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                        refetch()
                                    }}>
                                    <FontAwesomeIcon icon={faRefresh}/>&nbsp;Refresh
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="timeline">
                    {CategorizedLinkActivity(data.activities)}
                    <ReactPaginate
                        breakLabel="..."
                        pageCount={Math.ceil(data.total_count/10)}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName="pagination"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        pageClassName="page-item"
                        breakClassName="page-item"
                        nextClassName="page-item"
                        previousClassName="page-item"
                        previousLabel={<>&laquo;</>}
                        nextLabel={<>&raquo;</>}
                        forcePage={state.page}
                    />
                </div>
            </div>}
        </div>
    )
}

export default Activities
