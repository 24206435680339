import React, {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {WebsiteElementList, WebsiteElement} from "../../../interface/seo-change-tracking";
import {getData} from "../../../http-request";
import Loader from "../../../components/Loader/loader";
import Datatable from "../../../components/Datatable/datatable";
import {GridRenderCellParams} from "@mui/x-data-grid";
import './element.scss'
import {Button} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import {getDateTimeWithoutTimezone} from "../../../components/utils";

const Elements = (props: {website_id: number}) => {
    const { data, isFetching, refetch} = useQuery<WebsiteElementList, Error>('seo-change-tracking-website-elements',
        async () => {
            return await getData('/seo-change-tracking/elements/?website_id='+props.website_id);
        });
    const [rows, setRows] = useState<WebsiteElement[]>([])

    useEffect(() => {
        if(data?.elements){
            setRows(data?.elements)
        }
    }, [data])

    const columns = [
        {
            field: 'sn',
            headerName: 'S.N',
            flex: 0.1,
        },
        {
            field: 'link',
            headerName: 'Link',
            flex: 3,
            renderCell: (params: GridRenderCellParams) => (
                <a rel="noreferrer" target='_blank' href={params.value}>{params.value}</a>
            ),
        },
        {
            field: 'element',
            headerName: 'Element',
            flex: 0.5,
        },
        {
            field: 'location',
            headerName: 'Location',
            flex: 0.5,
        },
        {
            field: 'content',
            headerName: 'Content',
            flex: 4,
            renderCell: (params: GridRenderCellParams) => {
                try{
                    const json = JSON.parse(params.value)
                    let fullInfo = "<ul class='json-display-field'>"
                    Object.keys(json).forEach(value => {
                        if(json[value]){
                            if(value === 'href'){
                                fullInfo += `<li>${value} => <a rel="noreferrer" target='_blank' href=${json[value]}>${json[value]}</a></li>`
                            }else{
                                fullInfo += `<li>${value} => ${json[value]}</li>`
                            }
                        }
                    })
                    fullInfo += "</ul>"
                    return <div dangerouslySetInnerHTML={{__html: fullInfo}}/>
                }catch (e) {
                    return params.value
                }
            },
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            flex: 2,
            renderCell:(params => getDateTimeWithoutTimezone(params.value))
        },
        {
            field: 'updated_at',
            headerName: 'Last Updated',
            flex: 2,
            renderCell:(params => getDateTimeWithoutTimezone(params.value))
        },
    ]

    return (
        <div>
            {isFetching &&
            <Loader/>
            }
            {!isFetching && rows &&
            <div style={{width: '100%'}}>
                <div style={{textAlign: 'right'}} className={'mb-2'}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                            refetch()
                        }}>
                        <FontAwesomeIcon icon={faRefresh}/>&nbsp;Refresh
                    </Button>
                </div>
                <Datatable rows={rows.map((item: any, index: number) => {
                    return {...item, ...{sn: index + 1}}
                })} columns={columns}/>
            </div>
            }
        </div>
    )
}

export default Elements
