import React from "react";
import './mind_map.scss'

const jsMind = require("jsmind")

export const mindMap = (data) => {
    let elements = document.getElementsByClassName('jsmind-inner');
    if(elements.length > 0){
        elements[0].remove()
    }
    const mind = {
        "meta": {
            "name": "Google Search Console Clusterizer",
        },
        format:'node_tree',
        data: (data || {})['mind_map_data']
    };
    const options = {
        container: 'jsmind_container',
        editable: false,
        theme: 'greensea',
        view:{
            draggable: true,    // Drag the mind map with your mouse, when it's larger that the container
        },
    }
    return jsMind.show(options, mind)
}
