import React, {useEffect, useState} from "react";
import './vision.scss';
import {Formik} from "formik";
import { useMutation } from "react-query";
import Loader from "../../components/Loader/loader";
import BestGuessLabels from "./BestGuessLabel/best_guess_label";
import {VisionApiResponse} from "../../interface/vision";
import {getVisionAPIResult} from "../../http-request";
import TabComponent from "../../components/Tab/tab";
import WebEntities from "./WebEntity/web_entity";
import VisuallySimilarImage from "./VisuallySimilarImage/visually_similar_image";
import MatchingImages from "./MatchingImage/matching_images";
import CreateReport from "../Report/create_report";

const TabGenerator = (visionApiResponse: VisionApiResponse) => {
    return {
        "Best Guess Labels": <BestGuessLabels labels={visionApiResponse.rows.labels}/>,
        "Web Entities": <WebEntities web_entities={visionApiResponse.rows.web_entities}/>,
        "Pages With Matching Images Found": <MatchingImages matching_images={visionApiResponse.rows.matching_images}/>,
        "Visually Similar Image": <VisuallySimilarImage matching_images={visionApiResponse.rows.visually_similar_images}/>
    }
}

const Vision = () => {
    const mutation = useMutation(getVisionAPIResult);
    const [isReport, setIsReport] = useState(false)
    const [visionApiResponse, setVisionApiResponse] = useState<VisionApiResponse>()
    useEffect(() => {
        if(sessionStorage.getItem('report')){
            setVisionApiResponse(JSON.parse(sessionStorage.getItem('report') || '{}'))
            sessionStorage.removeItem('report')
            setIsReport(true)
        }
    }, []);
    return (
        <div>
            <div className='vision-form'>
                <Formik
                    initialValues={{ image_link: 'https://images.unsplash.com/photo-1544097797-bf8fc095364c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cHVibGljfGVufDB8fDB8fA%3D%3D&w=1000&q=80', image_file: '' }}
                    validate={values => {
                        const errors : {[index: string]:any} = {}
                        if (!values.image_link && !values.image_file) {
                            errors.image_link = 'please insert image link or upload a image';
                            errors.image_file = 'please insert image link or upload a image';
                        }
                        return errors;
                    }}
                    onSubmit={(values) => {
                        const submit = async () => {
                            mutation.mutateAsync({image_link: values.image_link, image_file: values.image_file},
                                {
                                    onSuccess: (res) => {
                                        setVisionApiResponse(res.data)
                                    }
                                });
                        }
                        submit()
                    }}>
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <div className='input-container'>
                                <div className='input-item'>
                                <textarea
                                    rows={5}
                                    className="form-control col-md-6"
                                    id="image_link"
                                    name="image_link"
                                    placeholder="Image Link"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.image_link}
                                />
                                    <span className='input-error'>{errors.image_link && touched.image_link && errors.image_link}</span>
                                </div>
                                <div className='separator'>
                                    <h3>OR</h3>
                                    <button disabled={mutation.isLoading} className='btn btn-info submit-button' type="submit">
                                        Submit
                                    </button>
                                </div>
                                <div className='input-item'>
                                    <input
                                        type="file"
                                        className="form-control col-md-6"
                                        name="image_file"
                                        id="image_file"
                                        accept="image/*"
                                        onChange={(event) => {
                                            const files = event.currentTarget.files;
                                            if(files !== null){
                                                setFieldValue("image_file", files[0]);
                                            }
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <span className='input-error'>{errors.image_file && touched.image_file && errors.image_file}</span>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            {mutation.isLoading ? (
                <Loader/>
            ) : (
                <div className="result">
                    {mutation.error instanceof Error ? (
                        <div>An error occurred: {mutation.error.message}</div>
                    ) : null}
                    {visionApiResponse !== undefined &&
                        <div>
                            {!isReport &&
                            <CreateReport data={visionApiResponse} tool_name={'Vision'}/>
                            }
                            <TabComponent labelContentMap={TabGenerator(visionApiResponse)}/>
                        </div>
                    }
                </div>
            )
            }
        </div>
    )
}

export default Vision;
